import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormControl } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';

import { ToastrService } from 'ngx-toastr';


import * as $ from 'jquery';
import { spawn } from 'child_process';


interface ItemData {
  id: number;
  name: string;
  age: number;
  address: string;
}

@Component({
  selector: 'app-model2',
  templateUrl: './model2.component.html',
  styleUrls: ['./model2.component.scss']
})
export class Model2Component implements OnInit {


  @Input() data: any;
  @Output() dataOutput = new EventEmitter<any>();

  infoTable: any;
  headers: any;
  source: any;
  title: any = false;
  isVisible: boolean = false;
  isVisibleModalAprobar: boolean = false;
  titleModal: any;
  titleModalAprobar: any;
  modalInfo: any;
  modalKeys: any = [];
  contentModal: any = '';
  classFunctions: any = '';
  disabled = ""
  dataPublicID:any;
  eventTarget:any;
  path: any = window.location.pathname;

  keyAprobar = new FormControl('');
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  public posHeaderAmount: number;

  //Expand
  mapOfExpandData: { [key: string]: boolean } = {
    
  };
  //*
   constructor(private cookie: CookieService, private router: Router, private api: ApiService, private modalService: NzModalService, private toastr: ToastrService) { }
   
   ngOnInit() {
     this.getInfo();
     this.api.validateMerchantPermissions();
    }
    //expand
   
    //*
    changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }
    });
  }
  formantExpand(array){
    let newarray = []
    let cont = 1

    array.map(res => {
      // res['expand'] = false
      // newarray.push()

      newarray = [...newarray, {...res, expand: false, id: cont}]
      cont++

    })
    return newarray
  }

  getInfo(){

    if(this.data.headers){

      if (this.data.title) {
        this.title = this.data.title;
      }
      this.headers = this.data.headers;

      this.source = this.data.source;
      this.infoTable = this.formantExpand(this.data.data);
      console.log('Infotable: ', this.infoTable);
      

    }else{

      this.data.then((data:any) => {

        $('#charging').addClass('hide');

        if(data.title){
          this.title = data.title;
        }
        this.headers = data.headers;


        this.source = data.source;
        this.infoTable = this.formantExpand(data.data);
        console.log('Infotable: ', this.infoTable);


      }).catch(error => {
        console.log('ERROR', error)
        if (error.status == 401) {
          this.cookie.set('ud', '');
          this.router.navigate(['/']);
        }
      });
    }

  }




  ngOnChanges() {
    this.getInfo();
  }

  getDataKeys(e, h){

    return e[h.key.split('-')[0]][h.key.split('-')[1]][h.key.split('-')[2]];
  }

  //INFORMATION OF REQUEST AND RESPONSE
  viewModal(e, type){

    this.titleModal = 'Información';
    this.contentModal = '';


    if (type === false){
      this.modalInfo = e;

      this.contentModal += `
                            <div class="d-flex">
                              <div class="col-6 font-weight-bold">Mensaje</div>
                              <div><b>:</b> ${this.modalInfo}</div>
                            </div>
                                `;


    }else{
      this.modalInfo = JSON.parse(JSON.parse(e));
      Object.keys(this.modalInfo).forEach(e => {
        switch (e) {
          case 'message':
            this.contentModal += `
                                <div class="d-flex">
                                  <div class="col-6 font-weight-bold">Mensaje</div>
                                  <div><b>:</b> ${this.modalInfo[e]}</div>
                                </div>
                                `;
            break;

          default:
            this.contentModal += `
                                <div class="d-flex">
                                  <div class="col-6 font-weight-bold">${e}</div>
                                  <div><b>:</b> ${this.modalInfo[e]}</div>
                                </div>
                                `;

            break;
        }
      });
    }




    this.showModal();


  }

  //MODAL

  success(msj): void {

    if(msj == ''){
      msj = 'Se aprobó correctamente';
    }
    const modal = this.modalService.success({
      nzTitle: 'Información',
      nzContent: msj,
      nzOkText: 'Aceptar'
    });

    setTimeout(() => modal.destroy(), 5000);
  }



  showModalSus(): void {
    this.isVisible = true;
  }


  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  showModalAprobar(): void {

    this.isVisibleModalAprobar = true;
    this.keyAprobar.setValue('');
  }

  handleOkAprobar(): void {
    var self = this;

    if (this.keyAprobar.value != '') {

      let data = {
        token: JSON.parse(this.cookie.get('ud')).token,
        merchant: this.merchant,
        service: 'cashout/payroll/put',
        id: this.dataPublicID,
        data: {
          key: this.keyAprobar.value,
          metadata: JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails),
        }
      }

      self.api.api(data).toPromise().then((res: any) => {
        $('#charging').addClass('hide');

        console.log('Mensaje', res);

        let msj = ''

        if (res.Status == 'False'){
          msj = res.message;
        }else{
          $(this.eventTarget).addClass('deshabilitado');
        }

        self.success(msj);
        self.isVisibleModalAprobar = false;



      })
        .catch(e => {
          console.log(e);
          self.isVisibleModalAprobar = false;
          $('#charging').addClass('hide');
          if (e.status == 401) {
            this.cookie.set('ud', '');
            this.router.navigate(['/']);
          }
        });

    }

  }

  handleCancelAprobar(): void {
    this.isVisibleModalAprobar = false;
  }

  //UTILITIES

  getNameStatus(e){
    if(e == 0){
      e = 'o'
    }
    return this.api.getStatusByID(e.toString())
  }

  getNameStatusCashOut(e) {
    if (e == 0) {
      e = 'o'
    }
    return this.api.getStatusByIDCashOut(e.toString())
  }

  getNameStatusCashInSttelement(e){
    return this.api.getStatusByIDCashInSttelement(e.toString())
  }

  getNameStatusCashOutOperations(e){
    return this.api.getStatusByIDCashoutOperations(e.toString())
  }

  getStatusByIDPayroll(e) {
    return this.api.getStatusByIDPayroll(e.toString())
  }


  //FUNCTIONS EVENTS

  functionsEvent(f, data, event=false){
    let info = {
      function: f,
      data: data,
      userDetails: JSON.parse(this.cookie.get('ud')).userDetails,
      event: event
    };
    this.dataOutput.emit(info);
  }

  functionsEvent2(f, data, event) {

    this.eventTarget = event.target;

    if (f === 'Aprobar') {
      if (data.LastStatus !== 2) {
        this.dataPublicID = data.PublicID;
        this.showModalAprobar();
      }
    }

    if( f === 'in'){
      let dir = "cashin-payments-orders/customer/"+ data.CustomerID;
      this.router.navigate(['/' + dir]);
    }
    if( f === 'out'){

      let driOut = "cashout-operations/customer/" + data.CustomerID;
      this.router.navigate(['/' + driOut]);


    }
    if( f === 'Procesar'){
      this.dataOutput.emit(data);
    }

    if( f === 'link'){}

    let info = {
      function: f,
      data: data,
      userDetails: JSON.parse(this.cookie.get('ud')).userDetails,
      event: event
    };
    this.dataOutput.emit(info);
  }


  getActiveOrInactive(e){

    if(e == true){
      return 'Activo'
    }else{
      return 'Inactivo'
    }

  }

  refreshValues() {
    console.log('Recargar Pagina');
    location.reload();
    //this.allCashinOrdersData = this.allCashinOrders(this.cookie.get('merchant'));
    //this.allCashinOrdersDataTmp = this.allCashinOrders(this.cookie.get('merchant'));
  }

  getTypeBankAcount(e) {

    if (e == 1) {
      return 'Liquidaciones'
    } else {
      return 'Otro'
    }

  }


  copyText(val: string, cust){


      let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.toastr.success(cust.FullName, 'Link Copiado');

  }

  clickUrl(){
    window.location.href = "/origin-edit";
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent  {
  notifications$ = new Observable<any>();

  @Input() set notifications(value: Observable<any>){
    this.notifications$ = value;
  }

  constructor() { }
}

import { ITotaltable } from "./../../../interfaces/ITotaltable";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "src/app/services/api.service";
import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import * as moment from "moment";

import { BehaviorSubject, combineLatest } from "rxjs";
import {debounceTime, take} from 'rxjs/operators';
import * as fileExcel from 'xlsx';
import * as FileSaver from "file-saver";
    
const headers = [
  {
    name: "Order ID",
    key: "orderId",
    opc: true,
  },
  {
    name: "Amount / Currency",
    key: "amount",
    opc: true,
  },
  {
    name: "Country",
    key: "country",
    opc: true,
  },
  {
    name: "Address",
    key: "street",
    opc: true,
  },
  {
    name: "Creation ",
    key: "createdDate",
    opc: true,
  },
  {
    name: "Last update",
    key: "lastUpdatedDate",
    opc: true,
  },
  {
    name: "Status",
    key: "status",
    opc: true,
  },
  {
    name: "Action",
    key: "opc3",
    opc: true,
  },
];

@Component({
  selector: "app-conciliation",
  templateUrl: "./conciliation.component.html",
  styleUrls: ["./conciliation.component.scss"],
})
export class ConciliationComponent implements OnInit {
  dataT: any;
  isVisibleError = false;
  isVisibleMiddle = false;
  
  lang: any = this.translate.getDefaultLang();

  allCashinOrdersData: any = this.allCashinOrders(10,0, this.cookie.get("merchant"));
  allCashinOrdersDataTmp: any = this.allCashinOrders(10,0, this.cookie.get("merchant"));
  acity;
  filters: any = this.getFilters();
  userDetails = JSON.parse(this.cookie.get("ud")).userDetails;
  MerchantActive = JSON.parse(this.cookie.get("ud")).MerchantActive;
  merchant: any = JSON.parse(this.cookie.get("ud")).MerchantActive[0]
    .MerchantData.MerchantCode;
  totaltable: ITotaltable = {
    countRow: 0,
    currency: "",
    totalCOP: 0,
    totalPEN: 0,
    TotalUSD: 0,
  };
  isDetailExportVisible = false;
  isNewPaymentOrderModalOpen = false;
  formPaymentOrder: FormGroup;
  isInstruccionesVisible = false;
  creationResponse = "test";
  showCompletedFieldErrorMessage = false;
  from_date: string = moment().format("YYYY-MM-DD");
  to_date: string = moment().format("YYYY-MM-DD");

  
  status$ = new BehaviorSubject<string>('');
  range$ = new BehaviorSubject<any>({
    start: moment(new Date()).format("YYYY-MM-DD"),
    end: moment(new Date()).format("YYYY-MM-DD")
  });
  search$ = new BehaviorSubject<any>('');
  pageSize$ = new BehaviorSubject<number>(10);
  pageIndex$ = new BehaviorSubject<any>(1);
  merchantCode = JSON.parse(this.cookie.get('merchant'));
  flag = false;

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private router: Router,
    public translate: TranslateService
  ) {
    this.formPaymentOrder = this.createFormGroup();
  }

  ngOnInit() {
    this.changeMerchant();
    this.translateChange();
    this.getQuerySelector();
    this.api.validateMerchantPermissions();
  }

  getDataTable(query: string) {
    this.api.getOrdersByPage(query).pipe(
      take(1)
    ).subscribe(data => {
      this.dataT = {
        data,
        headers
      };
    });
  }

  getOrdersByText(query: string) {
    this.api.getSearchOrders(query).pipe(
        debounceTime(500)
        // take(1)
    ).subscribe(data => {
      this.dataT = {
        data: {
          rows: data,
          totalRows: data.length
        },
        headers
      };
    });
  }

  getDate(value){
    switch(value){
      case 'today':
        return {
          start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
      }
      case 'yesterday': 
        return {
          start: moment().format("YYYY-MM-DD"),
          end: moment().subtract(1,'days').format("YYYY-MM-DD")
        }
      case 'week':
        return {
          start: moment().format("YYYY-MM-DD"),
          end: moment().subtract(1,'weeks').format("YYYY-MM-DD"),
        }
      case 'month': 
      return {
        start: moment().format("YYYY-MM-DD"),
        end: moment().subtract(1, 'months').format("YYYY-MM-DD"),
      }
      case 'trimester': 
      return {
        start: moment().format("YYYY-MM-DD"),
        end: moment().subtract(1, 'quarters').format("YYYY-MM-DD"),
      }
      case 'range': this.handleError();
    }
  }

  rangeDate(value): void{
    if(value){
      this.flag = true;
      this.setPagination(1);
      this.range$.next({
        start: moment(value[1]).format("YYYY-MM-DD"),
        end: moment(value[0]).format("YYYY-MM-DD")
      });
    }
  }

  setRange(value: any): void{
    this.flag = true;
    this.setPagination(1);
    this.range$.next(this.getDate(value));
  }

  setStatus(value: any): void{
    this.flag = true;
    this.setPagination(1);
    this.status$.next(value);
  }

  setSearch(value: any): void {
    console.log(value);
    this.flag = true;
    this.setPagination(1);
    this.search$.next(value);
  }

  setSizePage(value: number): void{
    this.flag = true;
    this.setPagination(1);
    this.pageSize$.next(value);
  }

  setPagination(value: any): void{    
    this.pageIndex$.next(value);
  }

  handleOkError(): void {
    console.log('click ok');
    this.isVisibleError = false;
  }


  showModalMiddle(): void {
    this.isVisibleMiddle = true;
  }

  handleOkMiddle(): void {
    console.log('click ok');
    this.isVisibleMiddle = false;
  }

  handleCancelMiddle(): void {
    this.isVisibleMiddle = false;
  }

  handleError(): void {
    this.isVisibleError = true;
  }

  handleCancelError(): void {
    this.isVisibleError = false;
  }

  getQuerySelector(): void {
    combineLatest([
      this.status$,
      this.range$,
      this.search$,
      this.pageIndex$,
      this.pageSize$
    ]).subscribe(([status, range, search, index, size]: [any, string, string, any, number]) => {
      if (!this.flag) {
        $("#charging").removeClass("hide");
        const query = this.getQuery(index, status, range, search, size);
        if (search) {
          this.getOrdersByText(query);
        } else {
          this.getDataTable(query);
        }
      } else {
        this.flag = false;
      }
    });
  }

  getQuery(index, status, range, search, size): string {
    if (search) {
    return `search?text=${search}&page=0&pageSize=${size}`;
    }
    return (index ? `merchantCode=${this.merchantCode}&pageSize=${size}&page=${index - 1}` : '') +
    (status ? `&status=${status}` : '') + (range ? `&startDate=${range.end}&endDate=${range.start}`: '') + (search ? `&search=${search}`: '');
  }

  createFormGroup() {
    return new FormGroup({
      MerchantSalesID: new FormControl("", [Validators.required]),
      Detail: new FormControl(""),
      Amount: new FormControl("", [Validators.required]),
      CurrencyCode: new FormControl("", [Validators.required]),
      TimeExpired: new FormControl("", [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
      ]),
      OkURL: new FormControl(""),
      ErrorURL: new FormControl(""),
      Channel: new FormControl("", [Validators.required]),
      FirstName: new FormControl("", [Validators.required]),
      LastName: new FormControl("", [Validators.required]),
      CountryCode: new FormControl("", [Validators.required]),
      DocType: new FormControl("", [Validators.required]),
      DocNumber: new FormControl("", [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(8),
      ]),
      Email: new FormControl(""),
      Mobile: new FormControl("", [
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(9),
        Validators.maxLength(9),
      ]),
    });
  }

  translateChange() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang === "en") {
        this.lang = "en";
      } else if (event.lang == "es") {
        this.lang = "es";
      }
    });
  }

  changeMerchant() {
    this.api.merchant$.subscribe((m) => {
      if (m.event === "change-merchant") {
        this.allCashinOrdersData = this.allCashinOrders(10, 1, m.data);
        this.allCashinOrdersDataTmp = this.allCashinOrders(10, 1, m.data);
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }
    });
  }

  async allCashinOrders(pageSize, page, merchant, dataInfo: any = false) {
    const dataP = {
      token: JSON.parse(this.cookie.get('ud')).token,
      service: 'indicators/orders-list',
      data: {
        merchantCode: this.merchantCode,
        pageSize: pageSize,
        page: page
      }
    };

    
    const headers = [
      {
        name: "Order ID",
        key: "orderId",
        opc: true,
      },
      {
        name: "Amount / Currency",
        key: "amount",
        opc: true,
      },
      {
        name: "Country",
        key: "country",
        opc: true,
      },
      {
        name: "Address",
        key: "City",
        opc: true,
      },
      {
        name: "Creation ",
        key: "createdAt",
        opc: true,
      },
      {
        name: "Last update",
        key: "updatedAt",
        opc: true,
      },
      {
        name: "Status",
        key: "status",
        opc: true,
      },
      {
        name: "Action",
        key: "opc3",
        opc: true,
      },
    ];

    this.dataT = {
      data: this.api.api(dataP).toPromise(),
      headers: headers
    }

  }

  async getFilters() {
    return [
      {
        name: "Periodo",
        key: ["from_date", "to_date"],
        type: "period",
      },
      {
        name: "Estado",
        key: "status",
        type: "select",
        data: this.api.getStatus(),
      },
    ];
  }

  processTransaction(data) {

    
    if (data.event && data.event == "pagination" && data.data) {

      this.allCashinOrders(data.data.pageSize, data.data.pageIndex, null);  
   
    } else {
      const _data: any = data.data;

      if (_data.LastStatus === 13) {
        const request = {
          token: JSON.parse(this.cookie.get("ud")).token,
          merchant: this.merchant,
          service: "cashin/transactions/confirm_recharge",
          type: "post",
          data: {
            PublicId: _data.PublicId,
          },
        };

        this.api
          .api(request)
          .toPromise()
          .then((res: any) => {
            $("#charging").addClass("hide");
            this.refreshValues();
          })
          .catch((e) => {
            $("#charging").addClass("hide");
            if (e.status === 401 || e.status === 0) {
              this.cookie.set("ud", "");
              this.router.navigate(["/"]);
            }
          });
      } else {
        alert("Esta opción esta desactivada.");
      }
    }
  }

  changeDataTable(data) {
    this.from_date = data.from_date;
    this.to_date = data.to_date;

    if (data.type && data.type === "search") {
      if (data.data === "") {
        this.allCashinOrdersData = this.allCashinOrdersDataTmp;
        this.allCashinOrdersDataTmp = this.allCashinOrdersData;
      } else {
        this.allCashinOrdersData = [];
        this.search(this.allCashinOrdersDataTmp, data.data);
      }
    } else {
      this.allCashinOrdersData = this.allCashinOrders(10, 0,
        this.cookie.get("merchant"),
        data
      );
      this.allCashinOrdersDataTmp = this.allCashinOrdersData;
    }
  }

  search(array, word) {
    word = word.toLowerCase();

    var res = [];
    var headers = [];

    if (array.headers) {
      headers = array.headers;
      var index = 0;
      var itemsProcessed = 0;
      array.data.forEach((element) => {
        for (var k in element) {
          if (element.hasOwnProperty(k)) {
            if (element[k].toString().toLowerCase().indexOf(word) > 1) {
              res.push(array.data[index]);
            } else {
              if (element[k] && typeof element[k] == "object") {
                for (var x in element[k]) {
                  for (var i in element[k][x]) {
                    if (
                      element[k][x][i] &&
                      element[k][x][i].toString().toLowerCase().indexOf(word) >
                        1
                    ) {
                      res.push(array.data[index]);
                    }
                  }
                }
              }
            }
          }
        }

        itemsProcessed++;
        if (itemsProcessed == array.data.length) {
          this.allCashinOrdersData = {
            data: res,
            headers: headers,
            source: "cashin-payments-orders",
          };
        }

        index++;
      });
    } else {
      array.then((e: any) => {
        headers = e.headers;
        var index = 0;
        var itemsProcessed = 0;
        e.data.forEach((element) => {
          for (var k in element) {
            if (element.hasOwnProperty(k)) {
              if (
                element[k] &&
                element[k].toString().toLowerCase().indexOf(word) > 1
              ) {
                res.push(e.data[index]);
              } else {
                if (element[k] && typeof element[k] == "object") {
                  for (var x in element[k]) {
                    for (var i in element[k][x]) {
                      if (
                        element[k][x][i] &&
                        element[k][x][i]
                          .toString()
                          .toLowerCase()
                          .indexOf(word) > 1
                      ) {
                        res.push(e.data[index]);
                      }
                    }
                  }
                }
              }
            }
          }

          itemsProcessed++;
          if (itemsProcessed == e.data.length) {
            this.allCashinOrdersData = {
              data: res,
              headers: headers,
              source: "cashin-payments-orders",
            };
          }

          index++;
        });
      });
    }
  }

  export() {
    const data = {
      token: JSON.parse(this.cookie.get("ud")).token,
      merchant: this.merchant,
      service: "cashin/transactions/download/",
      data: {
        from_date: this.from_date,
        to_date: this.to_date,
        language: this.lang,
      },
    };
    console.log("EXPORT", data);
    this.api.api(data);
    $("#charging").addClass("hide");
    this.toggleDetailExport();
  }

  toggleDetailExport() {
    this.isDetailExportVisible = !this.isDetailExportVisible;
  }

  exportConcar() {
    // CONFIRM SERVICE AND SERVICE NAME

    // let data = {
    //   token: JSON.parse(this.cookie.get('ud')).token,
    //   merchant: this.merchant,
    //   service: 'cashin/transactions/download/',
    //   data: {
    //     from_date: $('#f_start').val(),
    //     to_date: $('#f_end').val(),
    //     language: this.lang
    //   }
    // }

    // this.api.api(data);
    // $('#charging').addClass('hide');
    this.toggleDetailExport();
  }

  openCreatePaymentOrder() {
    this.isNewPaymentOrderModalOpen = !this.isNewPaymentOrderModalOpen;
  }

  handleCancel() {
    this.isInstruccionesVisible = false;
    this.formPaymentOrder = this.createFormGroup();
    this.isNewPaymentOrderModalOpen = !this.isNewPaymentOrderModalOpen;
    this.showCompletedFieldErrorMessage = false;
    this.refreshValues();
  }

  handleErrors() {
    if (this.formPaymentOrder.controls.Amount.status === "INVALID") {
      this.formPaymentOrder.controls.Amount.markAsDirty();
      this.formPaymentOrder.controls.Amount.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.Channel.status === "INVALID") {
      this.formPaymentOrder.controls.Channel.markAsDirty();
      this.formPaymentOrder.controls.Channel.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.CountryCode.status === "INVALID") {
      this.formPaymentOrder.controls.CountryCode.markAsDirty();
      this.formPaymentOrder.controls.CountryCode.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.CurrencyCode.status === "INVALID") {
      this.formPaymentOrder.controls.CurrencyCode.markAsDirty();
      this.formPaymentOrder.controls.CurrencyCode.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.Detail.status === "INVALID") {
      this.formPaymentOrder.controls.Detail.markAsDirty();
      this.formPaymentOrder.controls.Detail.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.DocNumber.status === "INVALID") {
      this.formPaymentOrder.controls.DocNumber.markAsDirty();
      this.formPaymentOrder.controls.DocNumber.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.DocType.status === "INVALID") {
      this.formPaymentOrder.controls.DocType.markAsDirty();
      this.formPaymentOrder.controls.DocType.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.Email.status === "INVALID") {
      this.formPaymentOrder.controls.Email.markAsDirty();
      this.formPaymentOrder.controls.Email.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.ErrorURL.status === "INVALID") {
      this.formPaymentOrder.controls.ErrorURL.markAsDirty();
      this.formPaymentOrder.controls.ErrorURL.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.FirstName.status === "INVALID") {
      this.formPaymentOrder.controls.FirstName.markAsDirty();
      this.formPaymentOrder.controls.FirstName.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.LastName.status === "INVALID") {
      this.formPaymentOrder.controls.LastName.markAsDirty();
      this.formPaymentOrder.controls.LastName.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.MerchantSalesID.status === "INVALID") {
      this.formPaymentOrder.controls.MerchantSalesID.markAsDirty();
      this.formPaymentOrder.controls.MerchantSalesID.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.Mobile.status === "INVALID") {
      this.formPaymentOrder.controls.Mobile.markAsDirty();
      this.formPaymentOrder.controls.Mobile.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.OkURL.status === "INVALID") {
      this.formPaymentOrder.controls.OkURL.markAsDirty();
      this.formPaymentOrder.controls.OkURL.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.TimeExpired.status === "INVALID") {
      this.formPaymentOrder.controls.TimeExpired.markAsDirty();
      this.formPaymentOrder.controls.TimeExpired.markAllAsTouched();
    }
  }

  handleOk() {
    if (this.formPaymentOrder.status === "INVALID") {
      this.showCompletedFieldErrorMessage = true;
      this.handleErrors();
    } else {
      this.showCompletedFieldErrorMessage = false;
      const data = {
        token: JSON.parse(this.cookie.get("ud")).token,
        merchant: this.merchant,
        service: "cashin/transactions/post",
        type: "post",
        data: {
          MerchantCode: this.merchant,
          MerchantSalesID: this.MerchantSalesID.value,
          Detail: this.Detail.value,
          Amount: this.Amount.value,
          CurrencyCode: this.CurrencyCode.value,
          TimeExpired: this.TimeExpired.value,
          OkURL: this.OkURL.value,
          ErrorURL: this.ErrorURL.value,
          Channel: this.Channel.value,
          FirstName: this.FirstName.value,
          LastName: this.LastName.value,
          CountryCode: this.CountryCode.value,
          DocType: this.DocType.value,
          DocNumber: this.DocNumber.value,
          Email: this.Email.value,
          Mobile: this.Mobile.value,
          Metadata: JSON.stringify(
            JSON.parse(this.cookie.get("ud")).userDetails
          ),
        },
      };

      if (!this.isInstruccionesVisible) {
        // this.api.api(data).toPromise().then((res: any) => {
        //   this.isInstruccionesVisible = true;
        //   this.creationResponse = res.UrlRedirect;
        // });

        this.api
          .api(data)
          .toPromise()
          .then((res: any) => {
            this.isInstruccionesVisible = true;
            this.creationResponse = res.UrlRedirect;
            $("#charging").addClass("hide");
            this.refreshValues();
          })
          .catch((e) => {
            console.log(e);
            $("#charging").addClass("hide");
            if (e.status === 401 || e.status === 0) {
              this.cookie.set("ud", "");
              this.router.navigate(["/"]);
            }
          });
      } else {
        this.handleCancel();
        this.refreshValues();
        this.creationResponse = "";
      }
    }
  }

  refreshValues() {
    this.allCashinOrdersData = this.allCashinOrders(10, 0,
      this.cookie.get("merchant")
    );
    this.allCashinOrdersDataTmp = this.allCashinOrders(10, 0,
      this.cookie.get("merchant")
    );
  }

  get MerchantSalesID() {
    return this.formPaymentOrder.get("MerchantSalesID");
  }
  get Detail() {
    return this.formPaymentOrder.get("Detail");
  }
  get Amount() {
    return this.formPaymentOrder.get("Amount");
  }
  get CurrencyCode() {
    return this.formPaymentOrder.get("CurrencyCode");
  }
  get TimeExpired() {
    return this.formPaymentOrder.get("TimeExpired");
  }
  get OkURL() {
    return this.formPaymentOrder.get("OkURL");
  }
  get ErrorURL() {
    return this.formPaymentOrder.get("ErrorURL");
  }
  get Channel() {
    return this.formPaymentOrder.get("Channel");
  }
  get FirstName() {
    return this.formPaymentOrder.get("FirstName");
  }
  get LastName() {
    return this.formPaymentOrder.get("LastName");
  }
  get CountryCode() {
    return this.formPaymentOrder.get("CountryCode");
  }
  get DocType() {
    return this.formPaymentOrder.get("DocType");
  }
  get DocNumber() {
    return this.formPaymentOrder.get("DocNumber");
  }
  get Email() {
    return this.formPaymentOrder.get("Email");
  }
  get Mobile() {
    return this.formPaymentOrder.get("Mobile");
  }
}

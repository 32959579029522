import {Component, OnInit} from '@angular/core';
import {ApiService} from 'src/app/services/api.service';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {FormGroup, FormControl, Validators, FormBuilder, Form} from '@angular/forms';
import {newOrders} from './models/new-orders';
import * as $ from 'jquery';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UtilsService} from 'src/app/services/utils.service';
import {Order, Provider} from '../../../../interfaces/IProvider';
import {NzModalService} from 'ng-zorro-antd/modal';

const packageToSend = {
    // providerCode: 40010,
    merchantCode: 1011,
    merchantSalesId: null,
    currencyCode: 'COP',
    countryCode: 'COL',
    amount: 10,
    type: 'COURIER',
    to: {
        country: '',
        city: '',
        state: '',
        contactTo: {}
    },
    settings: {
        assurance: false,
        backAndForth: false,
        weight: 1
    },
    declaredAmount: 28,
    discountAmount: 12,
    items: [
        {
            description: 'iPhone',
            serial_number: 'ax-3998-11',
            price: 150,
            weight: 20,
            length: 20,
            width: 20,
            height: 20
        }
    ]
};

@Component({
    selector: 'app-create-customer',
    templateUrl: './create-customer.component.html',
    styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent implements OnInit {

    public titlePage = 'Clientes > Nuevo Cliente';
    public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
    public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
    public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
    public fileName = 'Añadir foto de perfil';
    public srcPhoto = '/assets/img/photo.jfif';
    public selectedFile: File = null;
    public photoActual: string;
    public response = false;
    public validateForm: FormGroup;
    origenes = 'Origins';
    isVisibleMiddle = false;
    isVisibleError = false;
    public dataOrders: newOrders;
    public showDetail = false;
    public selectedOrigin: any;
    public fullTextAddress: string;
    public countries = [
        {id: 1, countryName: 'COLOMBIA', key: 'col', iso: 'COL'},
        {id: 2, countryName: 'PERU', key: 'per', iso: 'PER'}
    ];
    public selectedIndexTab = 0;
    public urbanElements = [
        'Calle',
        'Carrera',
        'Transversal',
        'Diagonal',
        'Avenida',
        'Bulevar',
        'Autopista',
        'Esquina',
        'Travesía'
    ];
    public typesProperty = [
        'Condominio',
        'Casa',
        'Torre',
        'Edificio'
    ];
    public units = [
        'KG',
        'OZ'
    ];
    public documentTypes = [
        {id: 1, label: 'DNI'},
        {id: 2, label: 'RUC'},
        {id: 3, label: 'CC'},
        {id: 4, label: 'NIT'},
    ];

    costPackage = 0;
    states$ = new Observable<any>();
    cities$ = new Observable<any>();
    origins$ = new Observable<any>();
    origins = [];
    formProfile: FormGroup;
    destinationForm: FormGroup;
    contactForm: FormGroup;
    fakeValue: any;
    provider: any = [];
    serviceSelectedIndex = null;
    serviceSelected = null;

    constructor(
        private api: ApiService,
        private cookie: CookieService,
        private router: Router,
        private fb: FormBuilder,
        private utilsService: UtilsService,
        private modal: NzModalService
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.changeMerchant();
        this.getOrigins();
        this.observeValues();
        // this.newOrders();
        // this.getProfile();
    }

    get fullAddress() {
        const country = this.validateForm.get('country').value;
        const department = this.validateForm.get('state').value;
        const city = this.validateForm.get('city').value;
        const postalCode = this.validateForm.get('postalCode').value;
        const street = this.validateForm.get('street').value + ' ' + this.validateForm.get('address').value;
        const addressNumber = this.validateForm.get('number').value;
        const typeProperty = this.validateForm.get('typeProperty').value;
        const detailProperty = this.validateForm.get('detailProperty').value;
        const floor = this.validateForm.get('floor').value ? `/ Piso ${this.validateForm.get('floor').value}` : '';

        // tslint:disable-next-line:max-line-length
        return `${country.countryName ? country.countryName : ''}, ${department.StateName ? department.StateName : ''}, ${city.MunicipalityName ? city.MunicipalityName : ''} / CP. ${postalCode} / ${street} # ${addressNumber} / ${typeProperty} ${detailProperty} ${floor}`;
    }

    get isMerchantSalesIdValid() {
        return this.validateForm.get('merchantSalesId').invalid && this.validateForm.get('merchantSalesId').touched;
    }

    get isCountryValid() {
        return this.validateForm.get('country').invalid && this.validateForm.get('country').touched;
    }

    get isDepartmentValid() {
        return this.validateForm.get('state').invalid && this.validateForm.get('state').touched;
    }

    get isCityValid() {
        return this.validateForm.get('city').invalid && this.validateForm.get('city').touched;
    }

    get isPostalCodeValid() {
        return this.validateForm.get('postalCode').invalid && this.validateForm.get('postalCode').touched;
    }

    get isTypePropertyValid() {
        return this.validateForm.get('typeProperty').invalid && this.validateForm.get('typeProperty').touched;
    }

    get isFloorValid() {
        return this.validateForm.get('floor').invalid && this.validateForm.get('floor').touched;
    }

    get isNumberValid() {
        return this.validateForm.get('number').invalid && this.validateForm.get('number').touched;
    }

    get isStreetValid() {
        return this.validateForm.get('street').invalid && this.validateForm.get('street').touched;
    }

    get isAddressValid() {
        return this.validateForm.get('address').invalid && this.validateForm.get('address').touched;
    }

    get isDocumentTypeValid() {
        return this.validateForm.get('documentType').invalid && this.validateForm.get('documentType').touched;
    }

    get isDocumentNumberValid() {
        return this.validateForm.get('documentNumber').invalid && this.validateForm.get('documentNumber').touched;
    }

    get isNameValid() {
        return this.validateForm.get('name').invalid && this.validateForm.get('name').touched;
    }

    get isLastNameValid() {
        return this.validateForm.get('lastName').invalid && this.validateForm.get('lastName').touched;
    }

    get isEmailValid() {
        return this.validateForm.get('email').invalid && this.validateForm.get('email').touched;
    }

    get isPhoneValid() {
        return this.validateForm.get('phone').invalid && this.validateForm.get('phone').touched;
    }

    get isDescriptionValid() {
        return this.validateForm.get('description').invalid && this.validateForm.get('description').touched;
    }

    get isWeightValid() {
        return this.validateForm.get('weight').invalid && this.validateForm.get('weight').touched;
    }

    get isUnitValid() {
        return this.validateForm.get('unit').invalid && this.validateForm.get('unit').touched;
    }

    get isQuantityValid() {
        return this.validateForm.get('quantity').invalid && this.validateForm.get('quantity').touched;
    }

    createForm() {
        this.validateForm = this.fb.group({
            merchantSalesId: ['', [Validators.required]],
            country: ['', [Validators.required]],
            state: ['', [Validators.required]],
            city: ['', [Validators.required]],
            postalCode: ['', [Validators.required]],
            street: ['', [Validators.required]],
            address: ['', [Validators.required]],
            number: ['', [Validators.required]],
            typeProperty: [''],
            detailProperty: [''],
            floor: [''],
            addressDetail: [''],
            documentType: ['', [Validators.required]],
            documentNumber: ['', [Validators.required]],
            name: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required]],
            description: ['', [Validators.required]],
            weight: ['', [Validators.required]],
            unit: ['', [Validators.required]],
            quantity: ['', [Validators.required]],
        });
    }

    submitForm(): void {
        for (const i in this.validateForm.controls) {
            if (this.validateForm.controls.hasOwnProperty(i)) {
                this.validateForm.controls[i].markAsDirty();
                this.validateForm.controls[i].updateValueAndValidity();
            }
        }
    }

    changeOrigin(event) {
        this.selectedOrigin = event;
        if (this.selectedOrigin) {
            const details = this.selectedOrigin.details[0];
            const mapAddress = {
                country: details.countryName,
                department: details.stateName,
                city: details.cityName,
                postalCode: details.postalCode,
                street: details.street,
                addressNumber: details.number
            };
            this.fullTextAddress = this.utilsService.getFullAddress({...mapAddress});
        }
    }

    changeMerchant() {
        this.api.merchant$.subscribe(m => {
            if (m.event === 'change-merchant') {
                this.merchant = m.data;
            }

        });
    }

    toggleDetail() {
        this.showDetail = !this.showDetail;
    }

    getOrigins(): void {
        this.origins$ = this.api.getOrigins(this.merchant);

        this.origins$.subscribe(data => {
            this.origins = data;
            const origin = data.find(item => item.isDefault);
            if (origin) {
                this.changeOrigin(origin);
            }
        });
    }

    getStates(country: any): void {
        this.states$ = this.api.getStates(country.key).pipe(map(res => res.data[0].States));
    }

    getCities(state): void {
        this.cities$ = this.api.getCities(state.StateID).pipe(map(res => res.data));
    }

    observeValues() {
        (this.validateForm.get('country').valueChanges as Observable<string>).subscribe((country) => this.getStates(country));
        (this.validateForm.get('state').valueChanges as Observable<number>).subscribe((state) => this.getCities(state));
    }

    onSelectedFile(e) {
        this.selectedFile = <File> e.target.files[0];
        this.fileName = this.selectedFile.name;
    }

    getProfile() {
        const data = {
            token: JSON.parse(this.cookie.get('ud')).token,
            merchant: this.merchant,
            service: 'configuration/user/' + this.merchant + '/' + this.userDetails.id,
            type: 'get-profile',
            data: ''
        };

        this.api.api(data).toPromise().then((res: any) => {

            $('#charging').addClass('hide');

            this.photoActual = res.image;

            const user = JSON.parse(this.cookie.get('ud'));
            user.userDetails.image = res.image;

            this.cookie.set('ud', JSON.stringify(user));

            if (res.is_active) {
                res.is_active = '1';
            }

            this.formProfile.setValue({
                fullname: res.fullname,
                mobile: res.mobile,
                profileId: '',
                email: res.email,
                status: res.is_active,
            });

            this.srcPhoto = res.image;
        })
            .catch(e => {
                if (e.status === 401) {
                    this.cookie.set('ud', '');
                    this.router.navigate(['/']);
                }
            });
    }

    assingValuesToPackage(): void {
        Object.keys(this.destinationForm.value).forEach(data => packageToSend.to[data] = this.destinationForm.get(data).value);
    }

    statimateCost(): void {
        if (this.validateForm.valid) {
            const dataProvider: Provider = {
                originId: this.selectedOrigin.originId,
                merchantCode: this.merchant,
                discountAmount: 0,
                declaredAmount: 0,
                to: {
                    country: this.validateForm.get('country').value.iso,
                    city: this.validateForm.get('city').value.MunicipalityCode,
                    state: this.validateForm.get('state').value.StateID,
                    street: this.validateForm.get('street').value + ' ' + this.validateForm.get('address').value,
                    number: this.validateForm.get('number').value,
                    addressDetail: this.validateForm.get('addressDetail').value,
                },
                items: [
                    {
                        description: this.validateForm.get('description').value,
                        weight: this.validateForm.get('weight').value ? this.validateForm.get('weight').value : 0,
                        quantity: this.validateForm.get('quantity').value,
                        unit: this.validateForm.get('unit').value,
                    }
                ],
                settings: {
                    weight: 1
                }
            };
            $('#charging').removeClass('hide');

            this.api.postStimate(dataProvider).subscribe((data: any) => {
                if (data.length > 0) {
                    $('#charging').addClass('hide');
                    this.provider = data;
                    this.showModalMiddle();
                } else {
                    $('#charging').addClass('hide');
                    this.handleError();
                }
            }, err => {
                this.modal.error({nzTitle: 'Something went wrong', nzContent: err.error.message});
                $('#charging').addClass('hide');
                this.handleError();
            });
        } else {
            this.destinationForm.markAllAsTouched();
        }
    }

    selectService(index, provider): void {
        this.serviceSelectedIndex = index;
        this.serviceSelected = provider.code;
    }

    showModalMiddle(): void {
        this.isVisibleMiddle = true;
    }

    nextTab(): void {
        this.selectedIndexTab += 1;
    }

    backTab(): void {
        this.selectedIndexTab -= 1;
    }

    selectChange(value): void {
        this.selectedIndexTab = value.index;
    }

    success(): void {
        this.modal.success({
            nzTitle: 'Orden creada',
            nzContent: 'Tu orden ha sido creada con éxito',
            nzOnOk: () => this.router.navigate(['orders']),
            nzWrapClassName: 'vertical-center-modal'
        });
    }

    error(): void {
        this.modal.error({
            nzTitle: 'Servicio inválido',
            nzContent: 'Tu orden no ha sido creada',
            nzWrapClassName: 'vertical-center-modal'
        });
    }

    confirmCourier(): void {
        this.isVisibleMiddle = false;
        if (!this.serviceSelectedIndex) {
            this.serviceSelectedIndex = 0;
        }
        const dataOrder: Order = {
            originId: this.selectedOrigin.originId,
            merchantSalesId: this.validateForm.get('merchantSalesId').value,
            providerCode: this.serviceSelected,
            merchantCode: this.merchant,
            currencyCode: this.provider[this.serviceSelectedIndex].currency,
            countryCode: this.validateForm.get('country').value.iso,
            amount: this.provider[this.serviceSelectedIndex].totalAmount,
            type: 'COURIER',
            to: {
                street: this.validateForm.get('street').value + ' ' + this.validateForm.get('address').value,
                number: this.validateForm.get('number').value,
                floor: this.validateForm.get('floor').value,
                apartment: this.validateForm.get('typeProperty').value + ' ' + this.validateForm.get('detailProperty').value,
                city: this.validateForm.get('city').value.MunicipalityCode,
                state: this.validateForm.get('state').value.StateID,
                postalCode: this.validateForm.get('postalCode').value,
                country: this.validateForm.get('country').value.iso,
                instructions: '',
                message: '',
                fullAddress: this.fullAddress,
                addressDetail: this.validateForm.get('addressDetail').value,
            },
            contactTo: {
                firstName: this.validateForm.get('name').value,
                lastName: this.validateForm.get('lastName').value,
                email: this.validateForm.get('email').value,
                phone: this.validateForm.get('phone').value,
                documentType: this.validateForm.get('documentType').value.label,
                documentNumber: this.validateForm.get('documentNumber').value
            },
            settings: {
                assurance: false,
                backAndForth: false,
                weight: 1
            },
            items: [
                {
                    description: this.validateForm.get('description').value,
                    weight: Number(this.validateForm.get('weight').value),
                    unit: this.validateForm.get('unit').value,
                    quantity: this.validateForm.get('quantity').value,
                    price: 0,
                    length: 0,
                    width: 0,
                    height: 0,
                    serialNumber: ''
                }
            ]
        };
        this.acceptProvider(dataOrder);
    }

    acceptProvider(value): void {
        $('#charging').removeClass('hide');
        this.api.postAcceptProvider(value).subscribe(data => {
            $('#charging').addClass('hide');
            this.success();
        }, (err) => {
            this.error();
        });
    }

    handleCancelMiddle(): void {
        this.isVisibleMiddle = false;
    }

    handleError(): void {
        this.isVisibleError = true;
    }

    handleOkError(): void {
        this.isVisibleError = false;
    }

    handleCancelError(): void {
        this.isVisibleError = false;
    }

    newOrders() {
        const dataO = {
            token: JSON.parse(this.cookie.get('ud')).token,
            service: 'origins/1111',
        };

        this.api
            .api(dataO)
            .toPromise()
            .then((result: any) => {

                this.dataOrders = result.map(res => res.details);
                console.log('dataOrders: ', this.dataOrders);
            })
            .catch((error) => {
                if (error.status === 401) {
                    sessionStorage.setItem('ud', '');
                    this.router.navigate(['/']);
                }
            });

    }

}

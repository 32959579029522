import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  getFullAddress({country, department, city, postalCode, street, addressNumber}) {
    // tslint:disable-next-line:max-line-length
    return `${country ? country : ''}, ${department ? department : ''}, ${city ? city : ''} / CP. ${postalCode} / ${street} ${addressNumber}`;
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  public details: any = this.processDetail();
  public historical: any = this.processHistorical();
  public activity: any = this.processActivity();
  public titlePage: any;
  public id:any;
  urlId: any;
  details$ = new Observable<any>();

  constructor(
    private aR: ActivatedRoute, 
    private cookie: CookieService, 
    private api: ApiService, 
    private router: Router
  ) { }

  public merchant: any = this.cookie.get('merchant');

  ngOnInit() {
    this.urlId = this.aR.snapshot.paramMap.get("id");
    // this.changeMerchant();
    // this.api.validateMerchantPermissions();
    this.getOrderDetail();
  }

  getOrderDetail(): void {
    this.details$ = this.api.detailOrder(this.urlId);
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }
    });
  }

  getSource() {
    let parameters = this.aR.snapshot.paramMap.get("id");
    let id = parameters.split('-')[0];
    let source = parameters.split('-')[1] + '-' + parameters.split('-')[2];

    if (parameters.split('-')[3]) {
      source += '-' + parameters.split('-')[3];
    }
    return {
      source: source,
      id: id
    };
  }

  async processDetail() {
    let source = this.getSource().source;
    let id = this.getSource().id;

    switch (source) {
      case 'cashin-reports-sttelement':
        this.titlePage = 'Cash In > Reportes > Liquidaciones > Detalle >';
        this.id = id;
        return {
          headers: this.getHeadersDetails(source),
          data: this.getDetails(id, 'settlement/detail/')
        };
      case 'cashin-payments-orders':
        this.titlePage = 'Cash In > Órdenes de pago > Detalle >';
        this.id = id;
        return {
          headers: this.getHeadersDetails(source),
          data: this.getDetails(id, 'cashin/transactions/detail/')
        };
      case 'cashout-operations':
        this.titlePage = 'Cash Out > Operaciones > Detalle >';
        this.id = id;
        return {
          headers: this.getHeadersDetails(source),
          data: this.getDetails(id, 'cashout/transactions/detail/')
        };
      case 'cashout-payroll':
        this.titlePage = 'Cash Out > Nóminas > Detalle >';
        this.id = id;
        return {
          headers: this.getHeadersDetails(source),
          data: this.getDetails(id, 'cashout/payroll/detail/')
        };
      default:
        break;
    }
  }


  async getDetails(id, service) {
    let data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      service: service,
      merchant: this.cookie.get('merchant'),
      data: id
    }
    return await this.api.api(data).toPromise();
  }


  getHeadersDetails(source) {

    switch (source) {

      case 'cashin-payments-orders':
        return [
          {
            name: 'Referencia',
            key: 'Transaction-MerchantSalesID'
          },
          {
            name: 'Monto',
            key: 'Transaction-Amount'
          },
          {
            name: 'Creation',
            key: 'Transaction-TxCreation'
          },
          {
            name: 'Cliente',
            key: 'Customer-FullName'
          },
          {
            name: 'Mora',
            key: 'Transaction-Amount'
          },
          {
            name: 'Payment due',
            key: 'Transaction-TxCreation'
          },
          {
            name: 'Description',
            key: 'Transaction-MerchantSalesID'
          },
          {
            name: 'Total',
            key: 'Transaction-Amount'
          },
          {
            name: 'Expiration',
            key: 'Transaction-TxCreation'
          }
        ];

      case 'cashin-reports-sttelement':
        return [
          {
            name: 'Referencia',
            key: 'RecordsTransactions-PublicId'
          },
          {
            name: 'Monto',
            key: 'Settlement-TotalAmount/Settlement-CurrencyCode'
          },
          {
            name: 'Sub Total',
            key: 'Settlement-SubTotal'
          }
        ];


      case 'cashout-operations':
        return [
          {
            name: 'Referencia',
            key: 'Transaction-MerchantReference'
          },
          {
            name: 'Monto',
            key: 'Transaction-Amount'
          },
          {
            name: 'Creación',
            key: 'Transaction-TxCreation'
          },
          {
            name: 'Cliente',
            key: 'Customer-FullName'
          },
          {
            name: 'Moneda',
            key: 'Transaction-CurrencyCode'
          },
          {
            name: 'Estado',
            key: 'Transaction-LastStatus'
          },
          {
            name: 'Aprobado',
            key: 'Transaction-IsApproval'
          },
          {
            name: 'Procesado',
            key: 'Transaction-IsProcessed'
          }
        ];

      case 'cashout-payroll':
        return [
          {
            name: 'Referencia',
            key: 'BatchFile-PublicID'
          },
          {
            name: 'Monto',
            key: 'BatchFile-TotalAmount'
          },
          {
            name: 'Total registros',
            key: 'BatchFile-TotalRecords'
          },
          {
            name: 'Total creados',
            key: 'BatchFile-TotalCreate'
          },
          {
            name: 'Moneda',
            key: 'BatchFile-CurrencyCode'
          },
          {
            name: 'Total Errores',
            key: 'BatchFile-TotalError'
          },
          {
            name: 'Fecha creación',
            key: 'BatchFile-created_at'
          }
        ];

      default:
        break;
    }

  }


  async processActivity() {

    let source = this.getSource().source;
    let id = this.getSource().id;

    switch (source) {
      case 'cashin-reports-sttelement':
        return await this.getDetails(id, 'settlement/detail/').then((data: any) => {
          let activity = [];
          data.Activity.forEach(e => {
            // e.Status = this.api.getStatusByIDCashInSttelement(e.Status.toString());
            activity.push(e);
          }); 

          return {
            headers: this.getHeadersActivity(source),
            data: activity,
            title: 'Notifications:'
          }

        }).catch(error => {
          if (error.status == 401) {
            this.cookie.set('ud', '');
            this.router.navigate(['/']);
          }
        });
      
      case 'cashin-payments-orders':
        return await this.getDetails(id, 'cashin/transactions/detail/').then((data: any) => {
          let activity = [];
          data.Activity.forEach(e => {
            e.StatusID = this.api.getStatusByID(e.StatusID.toString());
            activity.push(e);
          });

          return {
            headers: this.getHeadersActivity(source),
            data: activity,
            title: 'Notifications:'
          }

        }).catch(error => {
          if (error.status == 401) {
            this.cookie.set('ud', '');
            this.router.navigate(['/']);
          }
        });

      case 'cashout-operations':
        return await this.getDetails(id, 'cashout/transactions/detail/').then((data: any) => {
          let activity = [];
          data.Activity.forEach(e => {
            e.StatusID = this.api.getStatusByIDCashoutOperations(e.StatusID.toString());
            activity.push(e);
          });

          return {
            headers: this.getHeadersActivity(source),
            data: activity,
            title: 'Actividades:'
          }

        }).catch(error => {
          if (error.status == 401) {
            this.cookie.set('ud', '');
            this.router.navigate(['/']);
          }
        });

      case 'cashout-payroll':
        return await this.getDetails(id, 'cashout/payroll/detail/').then((data: any) => {

          let activity = [];
          data.Activity.forEach(e => {

            e.Status = this.api.getStatusByIDPayroll(e.Status.toString());
            activity.push(e);
          });


          return {
            headers: this.getHeadersActivity(source),
            data: activity,
            title: 'Actividades:'
          }

        }).catch(error => {
          if (error.status == 401) {
            this.cookie.set('ud', '');
            this.router.navigate(['/']);
          }
        });

        

      default:
        break;
    }

  }

 




  async processHistorical() {

    let source = this.getSource().source;
    let id = this.getSource().id;

    switch (source) {
      case 'cashin-reports-sttelement':

        return await this.getDetails(id, 'settlement/detail/').then((data: any) => {

          return {
            headers: this.getHeadersHistorical(source),
            data: data.RecordsTransactions,
            title: 'Lista de Transacciones:'
          }

        }).catch(error => {
          if (error.status == 401) {
            this.cookie.set('ud', '');
            this.router.navigate(['/']);
          }
        });
      
      case 'cashin-payments-orders':

        return await this.getDetails(id, 'cashin/transactions/detail/').then((data: any) => {

          return {
            headers: this.getHeadersHistorical(source),
            data: data.NotificationActivity,
            title: 'Historical:'
          }

        }).catch(error => {
          if (error.status == 401) {
            this.cookie.set('ud', '');
            this.router.navigate(['/']);
          }
        });
      
      case 'cashout-operations':

        return await this.getDetails(id, 'cashout/transactions/detail/').then((data: any) => {

          return {
            headers: this.getHeadersHistorical(source),
            data: data.NotificationActivity,
            title: 'Historico:'
          }

        }).catch(error => {
          if (error.status == 401) {
            this.cookie.set('ud', '');
            this.router.navigate(['/']);
          }
        });

      case 'cashout-payroll':
        return await this.getDetails(id, 'cashout/payroll/detail/').then((data: any) => {
          return {
            headers: this.getHeadersHistorical(source),
            data: data.ListTransactions,
            title: 'Lista de transacciones:'
          }

        }).catch(error => { 
          if (error.status == 401) {
            this.cookie.set('ud', '');
            this.router.navigate(['/']);
          }
        });

      default:
        break;
    }

  }


  getHeadersHistorical(source) {

    switch (source) {
      case 'cashin-reports-sttelement':
        return [
          {
            name: 'ID',
            key: 'PublicId'
          },
          {
            name: 'ID Comercio',
            key: 'MerchantSalesID'
          },
          {
            name: 'Codigo de pago',
            key: 'PaymentCode'
          },
          {
            name: 'Monto',
            key: 'Amount'
          },
          {
            name: 'Moneda',
            key: 'CurrencyCode'
          },
          {
            name: 'Sub Total',
            key: 'SubTotal'
          },
          {
            name: 'Comisión',
            key: 'Fee'
          },
          {
            name: 'Impuesto',
            key: 'Tax'
          }
          
        ];
      case 'cashin-payments-orders':
        return [
          {
            name: 'Date',
            key: 'id'
          },
          {
            name: 'Event',
            key: 'MerchantID'
          },
          {
            name: 'State',
            key: 'TransactionID'
          },
          {
            name: 'By',
            key: 'Request'
          }
        ];

      case 'cashout-operations':
        return [
          {
            name: 'ID',
            key: 'id'
          },
          {
            name: 'ID Comercio',
            key: 'MerchantID'
          },
          {
            name: 'ID Transacción',
            key: 'TransactionID'
          },
          {
            name: 'Petición',
            key: 'Request'
          },
          {
            name: 'Respuesta',
            key: 'Response'
          },
          {
            name: 'Resultado',
            key: 'Result'
          },
          {
            name: 'Fecha',
            key: 'created_at'
          },


        ];


      case 'cashout-payroll':
        return [
          {
            name: 'ID',
            key: 'PublicID'
          },
          {
            name: 'Referencia',
            key: 'MerchantReference'
          },
          {
            name: 'Fecha Creación',
            key: 'TxCreation'
          },
          {
            name: 'Monto',
            key: 'Amount'
          },
          {
            name: 'Moneda',
            key: 'CurrencyCode'
          },
          {
            name: 'Fecha de pago',
            key: 'PaymementDate'
          },
          {
            name: 'Estado',
            key: 'LastStatus'
          },
          {
            name: 'Banco',
            key: 'Customer-InfoBank-CustomerBank'
          },
          {
            name: 'Cliente',
            key: 'Customer-Info-FullName'
          },
          


        ];
      

      default:
        break;
    }

  }


  getHeadersActivity(source) {

    switch (source) {
      case 'cashin-reports-sttelement':
        return [
          {
            name: 'ID',
            key: 'id'
          },
          {
            name: 'Fecha',
            key: 'created_at'
          },
          {
            name: 'Estado',
            key: 'Status'
          },         

        ];

      case 'cashin-payments-orders':
        return [
          {
            name: 'Type',
            key: 'created_at'
          },
          {
            name: 'Receipt',
            key: 'StatusID'
          },
          {
            name: 'Affair',
            key: 'StatusID'
          },
          {
            name: 'Date',
            key: 'StatusID'
          },
          {
            name: 'Sent by',
            key: 'StatusID'
          },
          {
            name: 'State',
            key: 'StatusID'
          }
          // {
          //   name: 'Metadata',
          //   key: 'Metadata'
          // },

        ];

      case 'cashout-operations':
        return [
          {
            name: 'Fecha',
            key: 'created_at'
          },
          {
            name: 'Estado',
            key: 'StatusID'
          },
          // {
          //   name: 'Metadata',
          //   key: 'Metadata'
          // },

        ];

      case 'cashout-payroll':
        return [
          {
            name: 'Fecha',
            key: 'created_at'
          },
          {
            name: 'ID',
            key: 'PublicID'
          },
          {
            name: 'Estado',
            key: 'Status'
          },
          // {
          //   name: 'Metadata',
          //   key: 'Metadata'
          // },

        ];

        

      default:
        break;
    }

  }




}



import {RegisterComponent} from './components/auth/register/register.component';
import {LoginComponent} from './components/auth/login/login.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './components/pages/home/home.component';
import {RegisterConfirmationComponent} from './components/auth/register-confirmation/register-confirmation.component';


const routes: Routes = [
    {path: '', component: HomeComponent, pathMatch: 'full'},
    {path: 'login/:merchant/:data', component: LoginComponent, pathMatch: 'full'},
    {path: 'login', component: LoginComponent, pathMatch: 'full'},
    {path: 'register', component: RegisterComponent, pathMatch: 'full'},
    {path: 'cashin-payments-orders', component: HomeComponent, pathMatch: 'full'},
    {path: 'cashin-payments-orders/customer/:idCustomer', component: HomeComponent, pathMatch: 'full'},
    {path: 'cashin-reports-payments', component: HomeComponent, pathMatch: 'full'},
    {path: 'cashin-reports-settlement', component: HomeComponent, pathMatch: 'full'},
    {path: 'detail/:id', component: HomeComponent, pathMatch: 'full'},
    {path: 'cashout-operations', component: HomeComponent, pathMatch: 'full'},
    {path: 'cashout-operations/customer/:idCustomer', component: HomeComponent, pathMatch: 'full'},
    {path: 'cashout-reports', component: HomeComponent, pathMatch: 'full'},
    {path: 'orders', component: HomeComponent, pathMatch: 'full'},
    {path: 'configuration', component: HomeComponent, pathMatch: 'full'},
    {path: 'import/cashOut', component: HomeComponent, pathMatch: 'full'},
    {path: 'import/cashIn', component: HomeComponent, pathMatch: 'full'},
    {path: 'import/customers', component: HomeComponent, pathMatch: 'full'},
    {path: 'new-payroll', component: HomeComponent, pathMatch: 'full'},
    {path: 'configuration-api', component: HomeComponent, pathMatch: 'full'},
    {path: 'configuration-change-password', component: HomeComponent, pathMatch: 'full'},
    {path: 'configuration-profile', component: HomeComponent, pathMatch: 'full'},
    {path: 'configuration-user-maintenance', component: HomeComponent, pathMatch: 'full'},
    {path: 'configuration-webhook', component: HomeComponent, pathMatch: 'full'},
    {path: 'bank-acounts', component: HomeComponent, pathMatch: 'full'},
    {path: 'bank-acounts/service-product', component: HomeComponent, pathMatch: 'full'},
    {path: 'payroll', component: HomeComponent, pathMatch: 'full'},
    {path: 'register-confirmation', component: RegisterConfirmationComponent, pathMatch: 'full'},
    {path: 'orders', component: HomeComponent, pathMatch: 'full'},
    {path: 'orders/create-order', component: HomeComponent, pathMatch: 'full'},
    {path: 'origin-edit', component: HomeComponent, pathMatch: 'full'},
    {path: 'origin-edit/:id', component: HomeComponent},
    {path: 'origin-registry', component: HomeComponent, pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ApiService} from 'src/app/services/api.service';
import {CookieService} from 'ngx-cookie-service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import * as $ from 'jquery';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {NzModalService} from 'ng-zorro-antd/modal';
import {Register} from '../../../../../interfaces/IRegister';

const mainStructure = {
    metadata: {
        email: ''
    },
    originId: null,
    merchantCode: null,
    origin: {
        name: '',
        description: ''
    },
    originDetails: {}
};

@Component({
    selector: 'app-origin-edit',
    templateUrl: './origin-edit.component.html',
    styleUrls: ['./origin-edit.component.scss']
})
export class OriginEditComponent implements OnInit, AfterViewInit {
    public titlePage = 'Configuraciones > Mi perfil';

    id: any;
    formProfile: FormGroup;
    states$ = new Observable<any>();
    cities$ = new Observable<any>();
    public isEditable = false;
    private email;
    public urbanElements = [
        'Calle',
        'Carrera',
        'Transversal',
        'Diagonal',
        'Avenida',
        'Bulevar',
        'Autopista',
        'Esquina',
        'Travesía'
    ];
    public typesProperty = [
        'Condominio',
        'Casa',
        'Torre',
        'Edificio'
    ];
    public countries = [
        {id: 1, countryName: 'COLOMBIA', key: 'col', iso: 'COL'},
        {id: 2, countryName: 'PERU', key: 'per', iso: 'PER'},
    ];

    constructor(
        private api: ApiService,
        private cookie: CookieService,
        private router: Router,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private modal: NzModalService
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.params['id'];
        mainStructure.merchantCode = JSON.parse(this.cookie.get('merchant'));
        mainStructure.metadata.email = JSON.parse(this.cookie.get('ud')).userDetails.email;
        this.getElements();

    }

    ngAfterViewInit(): void {
        if (this.id) {
            this.getOrigins();
        }
    }

    get isNameValid() {
        return this.formProfile.get('name').invalid && this.formProfile.get('name').touched;
    }

    get isDescriptionValid() {
        return this.formProfile.get('description').invalid && this.formProfile.get('description').touched;
    }

    get isPhoneValid() {
        return this.formProfile.get('phone').invalid && this.formProfile.get('phone').touched;
    }

    get isEmailValid() {
        return this.formProfile.get('email').invalid && this.formProfile.get('email').touched;
    }

    get isCountryValid() {
        return this.formProfile.get('country').invalid && this.formProfile.get('country').touched;
    }

    get isStateIdValid() {
        return this.formProfile.get('state').errors && this.formProfile.get('state').touched;
    }

    get isCityIdValid() {
        return this.formProfile.get('city').errors && this.formProfile.get('city').touched;
    }

    get isPostalCodeValid() {
        return this.formProfile.get('postalCode').errors && this.formProfile.get('postalCode').touched;
    }

    get isStreetValid() {
        return this.formProfile.get('street').invalid && this.formProfile.get('street').touched;
    }

    get isAddressValid() {
        return this.formProfile.get('address').invalid && this.formProfile.get('address').touched;
    }

    get isNumberValid() {
        return this.formProfile.get('number').invalid && this.formProfile.get('number').touched;
    }

    get isTypePropertyValid() {
        return this.formProfile.get('typeProperty').invalid && this.formProfile.get('typeProperty').touched;
    }

    get isFloorValid() {
        return this.formProfile.get('floor').invalid && this.formProfile.get('floor').touched;
    }

    createForm() {
        this.formProfile = this.fb.group({
            name: ['', [Validators.required]],
            description: ['', [Validators.required]],
            country: ['', [Validators.required]],
            city: ['', [Validators.required]],
            state: ['', [Validators.required]],
            street: ['', [Validators.required]],
            address: ['', [Validators.required]],
            number: ['', [Validators.required]],
            postalCode: ['', [Validators.required]],
            isDefault: [false],
            typeProperty: [''],
            detailProperty: [''],
            floor: [''],
            addressDetail: [''],
            phone: ['', [Validators.required, Validators.minLength(7)]],
            email: ['', [Validators.required, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)]]
        });
    }

    getOrigins(): void {
        this.api.getOrigins(JSON.parse(this.cookie.get('merchant'))).subscribe(data => {
            const originById = data.find(origin => origin.originId === this.id);
            if (originById) {
                this.isEditable = true;
                this.formProfile.get('address').setValidators([]);
                this.assingValues({
                    ...originById.details[0],
                    name: originById.name,
                    description: originById.description,
                    isDefault: originById.isDefault,
                    street: originById.details[0].street.split(' ')[0],
                    address: originById.details[0].street.split(' ').slice(1).join(' '),
                    typeProperty: originById.details[0].apartment ? originById.details[0].apartment.split(' ')[0] : null,
                    detailProperty: originById.details[0].apartment ? originById.details[0].apartment.split(' ').slice(1).join(' ') : ''
                });
            }
        });
    }

    assingValues(value): void {
        Object.keys(this.formProfile.value).forEach(data => {
            if (data === 'country') {
                this.formProfile.get('country').setValue(this.countries.find(country => country.iso === value.country));
            } else if (data === 'state') {
                this.states$.subscribe(states => {
                    const currentState = states.find(state => state.StateID === Number(value.stateId));
                    this.formProfile.get('state').setValue(currentState);
                }, err => console.log(err));
            } else if (data === 'city') {
                this.formProfile.get('city').setValue({
                    CountryName: value.countryName,
                    MunicipalityCode: value.cityId,
                    MunicipalityName: value.cityName,
                    StateID: Number(value.stateId),
                    StateName: value.stateName
                });
            } else {
                this.formProfile.get(data).setValue(value[data]);
            }
        });
    }

    get fullAddress() {
        const country = this.formProfile.get('country').value;
        const department = this.formProfile.get('state').value;
        const city = this.formProfile.get('city').value;
        const postalCode = this.formProfile.get('postalCode').value;
        const street = this.formProfile.get('street').value + ' ' + (this.formProfile.get('address').value ? this.formProfile.get('address').value : '');
        const addressNumber = this.formProfile.get('number').value;
        const typeProperty = this.formProfile.get('typeProperty').value ? this.formProfile.get('typeProperty').value : '';
        const detailProperty = this.formProfile.get('detailProperty').value ? this.formProfile.get('detailProperty').value : '';
        const floor = this.formProfile.get('floor').value ? `/ Piso ${this.formProfile.get('floor').value}` : '';

        // tslint:disable-next-line:max-line-length
        return `${country.countryName ? country.countryName : ''}, ${department.StateName ? department.StateName : ''}, ${city.MunicipalityName ? city.MunicipalityName : ''} / CP. ${postalCode} / ${street} # ${addressNumber} / ${typeProperty} ${detailProperty} ${floor}`;
    }

    getElements(): void {
        (this.formProfile.get('country').valueChanges as Observable<string>).subscribe((country: any) => this.getStates(country.key));
        (this.formProfile.get('state').valueChanges as Observable<number>).subscribe((state) => this.getCities(state));
    }

    getStates(country: any) {
        this.states$ = this.api.getStates(country).pipe(map(res => res.data[0].States));
    }

    getCities(state: any): void {
        this.cities$ = this.api.getCities(state.StateID).pipe(map(res => res.data));
    }

    clearData(): void {
        if (this.id) {
            mainStructure.originId = this.id;
        }

        mainStructure.origin.name = this.formProfile.get('name').value;
        mainStructure.origin.description = this.formProfile.get('description').value;

        Object.keys(this.formProfile.value).forEach(name => {
            if (name !== 'name' && name !== 'description' && this.formProfile.get(name).value) {
                mainStructure.originDetails[name] = this.formProfile.get(name).value;
            }
        });
    }

    success(message): void {
        this.modal.success({nzTitle: message});
        setTimeout(() => {
            this.router.navigate(['origin-registry']);
        }, 500);
    }


    error(errors: Array<string>): void {
        let messageError: any;
        errors.forEach(msg => messageError += msg + '<br>');
        this.modal.error({nzTitle: 'Something went wrong', nzContent: messageError});
    }

    submit(): void {
        $('#charging').removeClass('hide');
        if (this.formProfile.valid) {
            // this.clearData();
            const dataStructure = this.getDataStructure();
            if (this.id) {
                this.api.updateOrigins({...dataStructure, originId: this.id}).subscribe(data => {
                    $('#charging').addClass('hide');
                    this.success('Registro actualizado');
                }, err => {
                    this.error(err.error.message);
                    $('#charging').addClass('hide');
                });
            } else {
                // delete mainStructure.originId;
                this.api.createOrigin(dataStructure).subscribe(data => {
                    $('#charging').addClass('hide');
                    this.success('Registro creado');
                }, err => {
                    $('#charging').addClass('hide');
                    this.error(err.error.message);
                });
            }
        } else {
            this.formProfile.markAllAsTouched();
        }
    }

    getDataStructure() {
        const data: Register = {
            metadata: {
                email: JSON.parse(this.cookie.get('ud')).userDetails.email
            },
            merchantCode: JSON.parse(this.cookie.get('merchant')),
            origin: {
                name: this.formProfile.get('name').value,
                description: this.formProfile.get('description').value,
                isDefault: this.formProfile.get('isDefault').value,
            },
            originDetails: {
                street: this.formProfile.get('street').value + ' ' + this.formProfile.get('address').value,
                number: this.formProfile.get('number').value,
                floor: this.formProfile.get('floor').value,
                addressDetail: this.formProfile.get('addressDetail').value,
                apartment: this.formProfile.get('typeProperty').value + ' ' + this.formProfile.get('detailProperty').value,
                cityId: this.formProfile.get('city').value.MunicipalityCode,
                stateId: this.formProfile.get('state').value.StateID,
                postalCode: this.formProfile.get('postalCode').value,
                country: this.formProfile.get('country').value.iso, // COL
                phone: this.formProfile.get('phone').value,
                email: this.formProfile.get('email').value,
            }
        };

        return data;
    }
}

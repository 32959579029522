import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-table-originregistry',
  templateUrl: './table-originregistry.component.html',
  styleUrls: ['./table-originregistry.component.scss']
})
export class TableOriginregistryComponent implements OnInit {
  origins$ = new Observable<any>();
  
  @Input() set origins(value){
    this.origins$ = value;
  }

  constructor() { }

  ngOnInit() {
  }

}

import { FormControl } from "@angular/forms";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import * as moment from "moment";
import * as $ from "jquery";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { dataDashboard } from "./models/data-dashboard";
import { chartDashboard } from "./models/chart-dashboard";
import { ChartComponent, ApexAxisChartSeries, ApexChart, ApexXAxis, ApexDataLabels, ApexStroke, ApexMarkers, ApexYAxis, ApexGrid, ApexTitleSubtitle, ApexLegend } from "ng-apexcharts";
import { BehaviorSubject, combineLatest } from "rxjs";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  tooltip: any; // ApexTooltip;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  dataD: dataDashboard;
  dataGraf: chartDashboard;

  @ViewChild("chart", null) chart: ChartComponent;

  // chart: ChartComponent;
  chartOptions: Partial<ChartOptions>;
  series: ApexAxisChartSeries;
 
  date = {
    start: new Date(),
    end: new Date(),
  };

  from = new Date();
  to = moment().subtract(1, 'days').format('YYYY/MM/DD');
  from$ = new BehaviorSubject<string>(moment().subtract(1, 'days').format('YYYY/MM/DD'));
  to$ = new BehaviorSubject<string>(moment().format('YYYY/MM/DD'));
  chartData: any;
  year: any;

  balance: any = "";
  currencyBalance: any = "";
  currencyBalanceInput = new FormControl("PEN");

  user: any;
  lang: any = this.translate.getDefaultLang();

  data: any = {};
  dataCashout: any = {};

  userDetails = JSON.parse(this.cookie.get("ud")).userDetails;
  MerchantActive = JSON.parse(this.cookie.get("ud")).MerchantActive;
  merchant: any = JSON.parse(this.cookie.get("ud")).MerchantActive[0].MerchantData.MerchantCode;
  convertion: any;
  merchantCashinActive: any = true;
  merchantCashoutActive: any = true;
  currencies: any = JSON.parse(this.cookie.get("ud")).MerchantActive[0].CurrencySupported;

  single: any[];
  view: any[] = [360, 140];
  dataChart1: any = {};
  dataChart2: any[];
  dataChart4: any[];
  dataChart5: any[];

  // options
  gradient = true;
  showLegend = true;
  showLabels = true;
  isDoughnut = false;

  styleDate = {
    "min-width": "200px",
    "padding-right": "20px",
  };


  from_date: any = moment()
    .startOf("month")
    .add(1, "days")
    .format("YYYY-MM-DD");
  from_date_cashout: any = moment()
    .startOf("month")
    .add(1, "days")
    .format("YYYY-MM-DD");
  to_date: any = moment().add(1, "days").format("YYYY-MM-DD");
  to_date_cashout: any = moment().add(1, "days").format("YYYY-MM-DD");

  // currency_code = new FormControl(this.currencies.Cashin[0].CurrencyCode);
  currency_code = new FormControl(this.currencies.Cashin[0].CurrencyCode);

  // currency_code2 = new FormControl(this.currencies.Cashout[0].CurrencyCode);
  currency_code2 = new FormControl("PEN");
  isVisible = false;
  dataMerchant = [];

  // TRADUCCIONES CUSTOMIZADAS

  Generic = "Genérico";
  Online = "En linea";
  Cash = "Efectivo";
  lastYears = [];

  balanceByBank: any[];

  constructor(
    private api: ApiService,
    private cookie: CookieService,
    private router: Router,
    public translate: TranslateService
  ) {}

  getLastYears(): void{
    const currentYear = new Date();
    for(let i = currentYear.getFullYear(); i > currentYear.getFullYear()-3;i--){
      this.lastYears.push(i);
    }
  }

 async getGraphicData(year: number) {    
    const dataC = {
      token: JSON.parse(this.cookie.get("ud")).token,
      service: "indicators/orders/count-by-month",
      data: {
        merchantCode: JSON.parse(this.cookie.get('merchant')),
        year: year,
      },
    };

    this.api.api(dataC).toPromise().then((result: any) => {
        let array = [];
        result.map((res) => {
          if (res.TOTAL) {
            let values = [];
            res.TOTAL.map((val) => {
              values = [...values, val.count];
            });          
            array = [...array, { name: "Órdenes totales", data: values }];
          }
          
          if (res.IN_PROGRESS) {
            let values = [];
            res.IN_PROGRESS.map((val) => {
              values = [...values, val.count];
            });
            array = [...array, { name: "Órdenes en curso", data: values }];
          }
          if (res.DELIVERED) {
            let values = [];
            res.DELIVERED.map((val) => {
              values = [...values, val.count];
            });
            array = [...array, { name: "Órdenes entregadas", data: values }];
          }
          if (res.CANCELED) {
            let values = [];
            res.CANCELED.map((val) => {
              values = [...values, val.count];
            });
            array = [...array, { name: "Órdenes rechazadas", data: values }];
          }
          
        });
        $("#charging").addClass("hide");

        const auxArray = new Array;
        auxArray.push(array[3])
        auxArray.push(array[1])
        auxArray.push(array[0])
        auxArray.push(array[2])


        this.buidChart(auxArray);
      }).catch((error) => {
        if (error.status === 401) {
          sessionStorage.setItem("ud", "");
          this.router.navigate(["/"]);
        }
      });
  }


  buidChart(value: any ): void{
    this.chartOptions = {
      series: value,   
      chart: {
        width: document.getElementById('container-row').clientWidth,
        height: 450,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        intersect: true,
        shared: false,
      },
      stroke: {
        width: 3,
        curve: "smooth",
        colors: ['#1890ff', '#28a745', '#ffc107', '#dc3545']
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
        
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
        ],
      },
      yaxis: {
        min: 0,
        max: 7.0,
        title: {
          text: "Engagement",
        },
      },
      legend: {
        horizontalAlign: "center",
        show: true,
        position: "right",
        itemMargin: {
          horizontal: 10,
          vertical: 10,
        },
        containerMargin: {
          left: 50,
          top: 50,
        },
      },
    };
  }

  setFrom(from): void{
    if(from)
      this.from$.next(from);
  }

  setTo(to): void{
    if(to)
      this.to$.next(to);
  }

  detectChangesDate(): void{
    combineLatest([
      this.from$,
      this.to$
    ]).subscribe(([from, to]: [any, any])=>{
      if(from && to){
        this.ordersCount(from, to);
      }
    });
  }

  ngOnInit() {
    const currentYear = new Date();
    this.getLastYears();
    this.getGraphicData(currentYear.getFullYear());
    this.detectChangesDate();
 
    this.validateMerchantPermissions();
    this.changeMerchant();
    this.getData();
    this.getDataCashout();
    this.translateChange();
    this.getBalancebyBank();
    
    Object.assign(this, this.dataChart2);
  }

  validateMerchantPermissions() {
    if (this.cookie.get("ud") !== "") {
      const merchantList = JSON.parse(this.cookie.get("ud")).MerchantActive;
      const selectedMerchantCode = JSON.parse(this.cookie.get("merchant"));
      let selectedItem;

      merchantList.forEach((item) => {
        if (item.MerchantData.MerchantCode === selectedMerchantCode) {
          selectedItem = item;
        }
      });

      if (selectedItem) {
        this.merchantCashinActive = selectedItem.MerchantData.ActiveCashin;
        this.merchantCashoutActive = selectedItem.MerchantData.ActiveCashout;

        this.merchantCashinActive
          ? $("#navCashin").show()
          : $("#navCashin").hide();
        this.merchantCashoutActive
          ? $("#navCashout").show()
          : $("#navCashout").hide();
      }
    }
  }

  translateChange() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang === "en") {
        this.Generic = "Generic";
        this.Online = "Online";
        this.Cash = "Cash";
      } else if (event.lang === "es") {
        this.Generic = "Genérico";
        this.Online = "En linea";
        this.Cash = "Efectivo";
      }
    });
  }

  changeMerchant() {
    this.api.merchant$.subscribe((m) => {
      if (m.event === "change-merchant") {
        this.validateMerchantPermissions();
        this.merchant = m.data;
        this.currencies = m.dataMerchant;
        this.getCurrenciesByIdMerchant(m.data, m.dataMerchant);
        this.getData();
        this.getDataCashout();
      }
    });
  }

  getCurrenciesByIdMerchant(id, data) {
    const i = 0;
    let currencySupported;
    data.forEach((e) => {
      if (e.MerchantData.MerchantCode === id) {
        this.currencies = e.CurrencySupported;
      }
    });
  }

  getBalance() {
    const data = {
      token: JSON.parse(this.cookie.get("ud")).token,
      service:
        "balance/" + this.merchant + "/" + this.currencyBalanceInput.value,
    };

    $("#charging").removeClass("hide");

    this.api
      .api(data)
      .toPromise()
      .then((res: any) => {
        $("#charging").addClass("hide");
        this.balance = res.Balance;
        this.currencyBalance = res.Currency;
      })
      .catch((e) => {
        $("#charging").addClass("hide");
        if (e.status === 401 || e.status === 0) {
          this.cookie.set("ud", "");
          this.router.navigate(["/"]);
        }
      });
  }

  private getBalancebyBank(): void {
    const data = {
      token: JSON.parse(this.cookie.get("ud")).token,
      service: "balancebyBanck",
      data: this.merchant,
    };

    $("#charging").removeClass("hide");

    this.api
      .api(data)
      .toPromise()
      .then((res: any) => {
        $("#charging").addClass("hide");
        this.balanceByBank = res.data;
      });
  }

  getData() {
    const data = {
      token: JSON.parse(this.cookie.get("ud")).token,
      merchant: this.merchant,
      service: "dasboard/cashin/",
      data: {
        from_date: moment(this.from_date).format("YYYY-MM-DD"),
        to_date: moment(this.to_date).format("YYYY-MM-DD"),
        currency_code: this.currency_code.value,
      },
    };

    $("#charging").removeClass("hide");

    this.api.api(data).toPromise().then((res: any) => {
        $("#charging").addClass("hide");

        // this.merchantCashinActive = true;
        // $('#navCashin').show();

        this.data = res;
        console.log('response', res);
        
        let dataRes = [];
        dataRes.push({
          name: "En linea",
          value:
            (parseInt(res.graphic2.percentage_online) / 100) *
            parseInt(res.graphic2.total_amount),
        });
        dataRes.push({
          name: "Efectivo",
          value:
            (parseInt(res.graphic2.percentage_cash) / 100) *
            parseInt(res.graphic2.total_amount),
        });

        this.dataChart2 = dataRes;        
        this.convertion = res.graphic3.Convertion;
        this.dataChart4 = res.graphic4;
        this.dataChart5 = res.graphic5;
      })
      .catch((e) => {
        $("#charging").addClass("hide");
        if (e.status === 401 || e.status === 0) {
          this.cookie.set("ud", "");
          this.router.navigate(["/"]);
        }
      });
  }

  getDataCashout() {
    const data = {
      token: JSON.parse(this.cookie.get("ud")).token,
      merchant: this.merchant,
      service: "dasboard/cashout/",
      data: {
        from_date: moment(this.from_date_cashout).format("YYYY-MM-DD"),
        to_date: moment(this.to_date_cashout).format("YYYY-MM-DD"),
        currency_code: this.currency_code.value,
      },
    };

    $("#charging").removeClass("hide");

    this.api
      .api(data)
      .toPromise()
      .then((res: any) => {
        $("#charging").addClass("hide");
        // this.merchantCashoutActive = true;
        // $('#navCashout').show();
        this.dataCashout = res;
      })
      .catch((e) => {
        $("#charging").addClass("hide");
        if (e.status === 401 || e.status === 0) {
          this.cookie.set("ud", "");
          this.router.navigate(["/"]);
        }
      });
  }

  onSelect(data): void {
    console.log("Item clicked", JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log("Activate", JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log("Deactivate", JSON.parse(JSON.stringify(data)));
  }

  toogleDetail(e) {
    $("#detail_" + e).toggleClass("hide");
  }

  toogleDetail2(e) {
    $("#detail2_" + e).toggleClass("hide");
  }


  ordersCount(start, end) {
    const dataS = {
      token: JSON.parse(this.cookie.get("ud")).token,
      service: "indicators/orders-count",
      data: {
        merchantCode: JSON.parse(this.cookie.get('merchant')),
        startDate: moment(start).format("YYYY/MM/DD"),
        endDate: moment(end).format("YYYY/MM/DD"),
      },
    };
    console.log("orderCount: ", dataS);
    const orderCount = [];
    $("#charging").removeClass("hide");

    this.api
      .api(dataS)
      .toPromise()
      .then((result: any) => {
        this.dataD = result;
        console.log("dataD: ", this.dataD);
        $("#charging").addClass("hide");
      })
      .catch((error) => {
        if (error.status === 401) {
          sessionStorage.setItem("ud", "");
          this.router.navigate(["/"]);
        }
      });
  }
}
import { ITotaltable } from './../../../../interfaces/ITotaltable';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-payments-orders',
  templateUrl: './payments-orders.component.html',
  styleUrls: ['./payments-orders.component.scss']
})
export class PaymentsOrdersComponent implements OnInit {

  lang: any = this.translate.getDefaultLang();

  public allCashinOrdersData: any = this.allCashinOrders(this.cookie.get('merchant'));
  public allCashinOrdersDataTmp: any = this.allCashinOrders(this.cookie.get('merchant'));
  public filters: any = this.getFilters();
  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  public totaltable: ITotaltable = {
    countRow : 0,
    currency: '',
    totalCOP: 0,
    totalPEN: 0,
    TotalUSD: 0
  };
  isDetailExportVisible = false;
  isNewPaymentOrderModalOpen = false;
  formPaymentOrder: FormGroup;
  isInstruccionesVisible = false;
  creationResponse = 'test';
  showCompletedFieldErrorMessage = false;
  from_date: string = moment().format('YYYY-MM-DD');
  to_date: string = moment().format('YYYY-MM-DD');

  constructor(private api: ApiService, private cookie: CookieService, private router: Router, public translate: TranslateService) {
    this.formPaymentOrder =   this.createFormGroup();
  }

  ngOnInit() {
    this.changeMerchant();
    this.translateChange();
    this.api.validateMerchantPermissions();
  }

  createFormGroup() {
    return new FormGroup({
      MerchantSalesID: new FormControl('', [Validators.required]),
      Detail: new FormControl(''),
      Amount: new FormControl('', [Validators.required]),
      CurrencyCode: new FormControl('', [Validators.required]),
      TimeExpired: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      OkURL: new FormControl(''),
      ErrorURL: new FormControl(''),
      Channel: new FormControl('', [Validators.required]),
      FirstName: new FormControl('', [Validators.required]),
      LastName: new FormControl('', [Validators.required]),
      CountryCode: new FormControl('', [Validators.required]),
      DocType: new FormControl('', [Validators.required]),
      DocNumber: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(8)]),
      Email: new FormControl(''),
      Mobile: new FormControl('', [Validators.pattern('^[0-9]*$'),
        Validators.minLength(9), Validators.maxLength(9)])
    });
  }

  translateChange() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang === 'en') {
        this.lang = 'en';
      } else if (event.lang == 'es') {
        this.lang = 'es';
      }
    });
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.allCashinOrdersData = this.allCashinOrders(m.data);
        this.allCashinOrdersDataTmp = this.allCashinOrders(m.data);
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }
    });
  }

  async allCashinOrders(merchant, dataInfo: any = false) {
    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: merchant,
      service: 'cashin/transactions/',
      data:{
        from_date:  moment().format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD'),
      }
    };

    const headers = [
      {
        'name': 'ID',
        'key': 'PublicId',
        'opc': true
      },
      {
        'name': 'Detalle',
        'key': 'DetailTransaction',
        'opc': true
      },
      {
        'name': 'Monto',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Cliente',
        'key': 'CustomerName',
        'opc': true
      },
      {
        'name': 'Código de pago',
        'key': 'PaymentCode',
        'opc': true
      },
      {
        'name': 'Creación',
        'key': 'created_at',
        'opc': true
      },
      {
        'name': 'Expiración',
        'key': 'TxExpiration',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'LastStatus',
        'opc': true
      },
      {
        'name': 'Opciones',
        'key': 'opc',
        'opc': true,
        'functions': ['Procesar']
      }
    ];

    if (dataInfo !== false) {
      data.service = 'cashin/transactions/WithParams';
      data.data = dataInfo;
    }
    const result = await this.api.api(data).toPromise() as any[];
    this.totaltable = {
      countRow : 0,
      currency: '',
      totalCOP: 0,
      totalPEN: 0,
      TotalUSD: 0
    };
    result.forEach((item, i) => {
      let laguage = 'es-CO';
      if (item.CurrencyCode === 'PEN') {
        laguage = 'es-PE';
        this.totaltable.totalPEN += Number(String(item.Amount).replace(',', '.'));
      }
      if (item.CurrencyCode === 'USD') {
        laguage = 'en-US';
        this.totaltable.TotalUSD += Number(String(item.Amount).replace(',', '.'));
      }
      if (item.CurrencyCode === 'COP') {
        this.totaltable.totalCOP += Number(String(item.Amount).replace(',', '.'));
      }
      const formatter = new Intl.NumberFormat(laguage, {
        style: 'decimal',
        currency: item.CurrencyCode,
        minimumFractionDigits: 2,
      });
      result[i].Amount = formatter.format(Number(String(item.Amount).replace(',', '.')));
      // result[i].Amount = Number(9000000.01).toLocaleString('es', {maximumFractionDigits: 0,  minimumFractionDigits: 0});
    });
    this.totaltable.currency = 'COP';
    this.totaltable.countRow = result.length;
    return {
      headers: headers,
      data: result,
      source: 'cashin-payments-orders'
    };

  }



  async getFilters() {

    return [
      {
        'name': 'Periodo',
        'key': ['from_date', 'to_date'],
        'type': 'period'
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': this.api.getStatus()
      }

    ];

  }

  processTransaction(data){
    const _data: any = data.data;

    if (_data.LastStatus === 13) {
      const request = {
        token: JSON.parse(this.cookie.get('ud')).token,
        merchant: this.merchant,
        service: 'cashin/transactions/confirm_recharge',
        type: 'post',
        data: {
          PublicId: _data.PublicId
        }
      };

      this.api.api(request).toPromise().then((res: any) => {
        $('#charging').addClass('hide');
        this.refreshValues();
      })
      .catch(e => {
        $('#charging').addClass('hide');
        if (e.status === 401 || e.status === 0) {
          this.cookie.set('ud', '');
          this.router.navigate(['/']);
        }
      });

    } else{
      alert('Esta opción esta desactivada.');
    }
    
  }

  changeDataTable(data) {

    this.from_date = data.from_date;
    this.to_date = data.to_date;

    if (data.type && data.type === 'search') {
      if(data.data === "") {
        this.allCashinOrdersData = this.allCashinOrdersDataTmp;
        this.allCashinOrdersDataTmp = this.allCashinOrdersData;
      }else{
        this.allCashinOrdersData = [];
        this.search(this.allCashinOrdersDataTmp, data.data);
      }

    } else {
      this.allCashinOrdersData = this.allCashinOrders(this.cookie.get('merchant'), data);
      this.allCashinOrdersDataTmp = this.allCashinOrdersData;
    }
  }

  search(array, word) {

    word = word.toLowerCase();

    var res = [];
    var headers = [];

    if (array.headers) {

      headers = array.headers;
      var index = 0;
      var itemsProcessed = 0;
      array.data.forEach(element => {

        for (var k in element) {
          if (element.hasOwnProperty(k)) {
            if (element[k].toString().toLowerCase().indexOf(word) > 1) {
              res.push(array.data[index]);
            } else {
              if (element[k] && typeof (element[k]) == 'object') {
                for (var x in element[k]) {
                  for (var i in element[k][x]) {
                    if (element[k][x][i] && element[k][x][i].toString().toLowerCase().indexOf(word) > 1) {
                      res.push(array.data[index]);
                    }
                  }
                }
              }
            }
          }
        }

        itemsProcessed++
        if (itemsProcessed == array.data.length) {
          this.allCashinOrdersData = {
            data: res,
            headers: headers,
            source: 'cashin-payments-orders'
          };
        }

        index++;
      });

    } else {

      array.then((e: any) => {

        headers = e.headers;
        var index = 0;
        var itemsProcessed = 0;
        e.data.forEach(element => {
          for (var k in element) {
            if (element.hasOwnProperty(k)) {


              if (element[k] && element[k].toString().toLowerCase().indexOf(word) > 1) {
                res.push(e.data[index]);
              } else {
                if (element[k] && typeof (element[k]) == 'object') {
                  for (var x in element[k]) {
                    for (var i in element[k][x]) {
                      if (element[k][x][i] && element[k][x][i].toString().toLowerCase().indexOf(word) > 1) {
                        res.push(e.data[index]);
                      }
                    }
                  }
                }
              }
            }
          }

          itemsProcessed++
          if (itemsProcessed == e.data.length) {
            this.allCashinOrdersData = {
              data: res,
              headers: headers,
              source: 'cashin-payments-orders'
            };
          }

          index++;
        });
      });
    }

  }

  export() {
    const data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'cashin/transactions/download/',
      data: {
        from_date: this.from_date,
        to_date: this.to_date,
        language: this.lang
      }
    };
    console.log('EXPORT', data)
    this.api.api(data);
    $('#charging').addClass('hide');
    this.toggleDetailExport();
  }

  toggleDetailExport() {
    this.isDetailExportVisible = !this.isDetailExportVisible;
  }

  exportConcar() {
    // CONFIRM SERVICE AND SERVICE NAME

    // let data = {
    //   token: JSON.parse(this.cookie.get('ud')).token,
    //   merchant: this.merchant,
    //   service: 'cashin/transactions/download/',
    //   data: {
    //     from_date: $('#f_start').val(),
    //     to_date: $('#f_end').val(),
    //     language: this.lang
    //   }
    // }

    // this.api.api(data);
    // $('#charging').addClass('hide');
    this.toggleDetailExport();
  }

  openCreatePaymentOrder() {
    this.isNewPaymentOrderModalOpen = !this.isNewPaymentOrderModalOpen;
  }

  handleCancel() {
    this.isInstruccionesVisible = false;
    this.formPaymentOrder =   this.createFormGroup();
    this.isNewPaymentOrderModalOpen = !this.isNewPaymentOrderModalOpen;
    this.showCompletedFieldErrorMessage = false;
    this.refreshValues();
  }

  handleErrors() {

    if (this.formPaymentOrder.controls.Amount.status === 'INVALID') {
      this.formPaymentOrder.controls.Amount.markAsDirty();
      this.formPaymentOrder.controls.Amount.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.Channel.status === 'INVALID') {
      this.formPaymentOrder.controls.Channel.markAsDirty();
      this.formPaymentOrder.controls.Channel.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.CountryCode.status === 'INVALID') {
      this.formPaymentOrder.controls.CountryCode.markAsDirty();
      this.formPaymentOrder.controls.CountryCode.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.CurrencyCode.status === 'INVALID') {
      this.formPaymentOrder.controls.CurrencyCode.markAsDirty();
      this.formPaymentOrder.controls.CurrencyCode.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.Detail.status === 'INVALID') {
      this.formPaymentOrder.controls.Detail.markAsDirty();
      this.formPaymentOrder.controls.Detail.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.DocNumber.status === 'INVALID') {
      this.formPaymentOrder.controls.DocNumber.markAsDirty();
      this.formPaymentOrder.controls.DocNumber.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.DocType.status === 'INVALID') {
      this.formPaymentOrder.controls.DocType.markAsDirty();
      this.formPaymentOrder.controls.DocType.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.Email.status === 'INVALID') {
      this.formPaymentOrder.controls.Email.markAsDirty();
      this.formPaymentOrder.controls.Email.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.ErrorURL.status === 'INVALID') {
      this.formPaymentOrder.controls.ErrorURL.markAsDirty();
      this.formPaymentOrder.controls.ErrorURL.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.FirstName.status === 'INVALID') {
      this.formPaymentOrder.controls.FirstName.markAsDirty();
      this.formPaymentOrder.controls.FirstName.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.LastName.status === 'INVALID') {
      this.formPaymentOrder.controls.LastName.markAsDirty();
      this.formPaymentOrder.controls.LastName.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.MerchantSalesID.status === 'INVALID') {
      this.formPaymentOrder.controls.MerchantSalesID.markAsDirty();
      this.formPaymentOrder.controls.MerchantSalesID.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.Mobile.status === 'INVALID') {
      this.formPaymentOrder.controls.Mobile.markAsDirty();
      this.formPaymentOrder.controls.Mobile.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.OkURL.status === 'INVALID') {
      this.formPaymentOrder.controls.OkURL.markAsDirty();
      this.formPaymentOrder.controls.OkURL.markAllAsTouched();
    }

    if (this.formPaymentOrder.controls.TimeExpired.status === 'INVALID') {
      this.formPaymentOrder.controls.TimeExpired.markAsDirty();
      this.formPaymentOrder.controls.TimeExpired.markAllAsTouched();
    }

  }

  handleOk() {
    if (this.formPaymentOrder.status === 'INVALID') {
      this.showCompletedFieldErrorMessage = true;
      this.handleErrors();
    } else {
      this.showCompletedFieldErrorMessage = false;
      const data = {
        token: JSON.parse(this.cookie.get('ud')).token,
        merchant: this.merchant,
        service: 'cashin/transactions/post',
        type: 'post',
        data: {
          MerchantCode: this.merchant,
          MerchantSalesID: this.MerchantSalesID.value,
          Detail: this.Detail.value,
          Amount: this.Amount.value,
          CurrencyCode: this.CurrencyCode.value,
          TimeExpired: this.TimeExpired.value,
          OkURL: this.OkURL.value,
          ErrorURL: this.ErrorURL.value,
          Channel: this.Channel.value,
          FirstName: this.FirstName.value,
          LastName: this.LastName.value,
          CountryCode: this.CountryCode.value,
          DocType: this.DocType.value,
          DocNumber: this.DocNumber.value,
          Email: this.Email.value,
          Mobile: this.Mobile.value,
          Metadata: JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails),

        }
      };

      if (!this.isInstruccionesVisible) {
        // this.api.api(data).toPromise().then((res: any) => {
        //   this.isInstruccionesVisible = true;
        //   this.creationResponse = res.UrlRedirect;
        // });

        this.api.api(data).toPromise().then((res: any) => {
          this.isInstruccionesVisible = true;
          this.creationResponse = res.UrlRedirect;
          $('#charging').addClass('hide');
          this.refreshValues();
        })
        .catch(e => {
          console.log(e);
          $('#charging').addClass('hide');
          if (e.status === 401 || e.status === 0) {
            this.cookie.set('ud', '');
            this.router.navigate(['/']);
          }
        });

      } else {
        this.handleCancel();
        this.refreshValues();
        this.creationResponse = '';
      }
    }
  }

  refreshValues() {
    this.allCashinOrdersData = this.allCashinOrders(this.cookie.get('merchant'));
    this.allCashinOrdersDataTmp = this.allCashinOrders(this.cookie.get('merchant'));
  }

  get MerchantSalesID() { return this.formPaymentOrder.get('MerchantSalesID'); }
  get Detail() { return this.formPaymentOrder.get('Detail'); }
  get Amount() { return this.formPaymentOrder.get('Amount'); }
  get CurrencyCode() { return this.formPaymentOrder.get('CurrencyCode'); }
  get TimeExpired() { return this.formPaymentOrder.get('TimeExpired'); }
  get OkURL() { return this.formPaymentOrder.get('OkURL'); }
  get ErrorURL() { return this.formPaymentOrder.get('ErrorURL'); }
  get Channel() { return this.formPaymentOrder.get('Channel'); }
  get FirstName() { return this.formPaymentOrder.get('FirstName'); }
  get LastName() { return this.formPaymentOrder.get('LastName'); }
  get CountryCode() { return this.formPaymentOrder.get('CountryCode'); }
  get DocType() { return this.formPaymentOrder.get('DocType'); }
  get DocNumber() { return this.formPaymentOrder.get('DocNumber'); }
  get Email() { return this.formPaymentOrder.get('Email'); }
  get Mobile() { return this.formPaymentOrder.get('Mobile'); }

}

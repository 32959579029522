import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from './../../../services/api.service';
import { Component, OnInit, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UploadChangeParam } from 'ng-zorro-antd/upload';
import * as XLSX from 'xlsx';
import * as $ from 'jquery';



const { read, write, utils } = XLSX;
type AOA = any[][];

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})

export class ImportComponent implements OnInit {

  @Input() title: any;
  importType = '';
  titlePage = '';
  step = 1;
  data: AOA = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  headers: any = [];
  body: any = [];
  file:any;
  formData: FormData = new FormData();
  titleFile:String =  'Drag and drop your file';
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  confirmation:any = null;
  Process = '';
  Error = '';
  isFinalStep = false;

  constructor(private msg: NzMessageService, private api: ApiService, private cookie: CookieService, public router: Router) { }

  ngOnInit() {
    this.formatInputData();
    this.changeMerchant();
    this.api.validateMerchantPermissions();
  }

  formatInputData() {

    if (this.title === 'cashout') {
      this.titlePage = 'Cash Out > Operaciones > Importar';
      this.importType = 'cashout/payroll';
    }
    if (this.title === "cashin"){
      this.titlePage = 'cashin > orders > Importar';
      this.importType = 'cashin/transactions/batchfile';

    }
    if((this.title === "customers")) {
      this.titlePage = 'customers > Importar';
      this.importType = 'customer/batchfile';
    }
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event === 'change-merchant') {
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }

    });
  }

  onFileChange(evt: any) {

    this.file = evt.dataTransfer ? evt.dataTransfer.files[0] : evt.target.files[0];
    this.data = [];
    this.headers = [];
    this.body = [];
    this.step = 2;
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      this.headers = this.data[0]
      for (let i = 1; i < this.data.length; i++) {
        this.body.push(this.data[i]);
      }

    };
    reader.readAsBinaryString(target.files[0]);

  }

  next() {
    var self = this;
    console.log(this.file);


    var file = new FormData();

    if (this.file) {
      file.append('file', this.file, this.file.name);
      file.append('metadata', JSON.stringify(JSON.parse(this.cookie.get('ud')).userDetails));
      file.append('merchant_code', this.merchant);
    }

    if (this.step === 2) {

      let data = {
        token: JSON.parse(this.cookie.get('ud')).token,
        service: this.importType,
        data: file
      };

      this.api.api(data).toPromise().then((data: any) => {
        $('#charging').addClass('hide');

        this.Process = data.Process;
        this.Error = data.Error;

        if (this.importType = 'customer/batchfile'){
          this.Process = data.total_procces;
          this.Error = data.total_error;
        }


        if (data.Error > 0) {
          this.confirmation = false;
          console.log("error");

        } else {
          console.log("Sin error");
          this.isFinalStep = true;
          this.confirmation = true;
        }
      }).catch(error => {
        $('#charging').addClass('hide');
        if (error.status === 401) {
          this.cookie.set('ud', '');
          this.router.navigate(['']);
        }
      });
    }

    if (this.isFinalStep) {
      if (this.importType === 'cashout/payroll') {
        this.router.navigate(['/cashout-operations']);
      } if (this.importType = 'customer/batchfile'){
        this.router.navigate(['/customers']);
      }
      else {
        this.router.navigate(['/cashin-payments-orders']);
      }
    }
  }
}

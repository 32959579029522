import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-historical',
  templateUrl: './historical.component.html',
  styleUrls: ['./historical.component.scss']
})
export class HistoricalComponent implements OnInit {
  historical$ = new Observable<any>();

  @Input() set historical(value: Observable<any>){
    this.historical$ = value;
  }

  constructor() { }

  ngOnInit() {
  }

}

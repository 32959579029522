import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ITotaltable } from '../../../../interfaces/ITotaltable';
import * as moment from 'moment';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  lang: any = this.translate.getDefaultLang(); 

  public allCashinOrdersData: any = this.allCashinOrders(this.cookie.get('merchant'), { status: 15 }); //SOLO STATUS 15
  public allCashinOrdersDataTmp: any = this.allCashinOrders(this.cookie.get('merchant'), { status: 15 }); //SOLO STATUS 15
  public filters: any = this.getFilters();
  public titlePage: string = 'Cash Out > Reportes > Transferencias';
  public userDetails = JSON.parse(this.cookie.get('ud')).userDetails;
  public MerchantActive = JSON.parse(this.cookie.get('ud')).MerchantActive;
  public merchant: any = JSON.parse(this.cookie.get('ud')).MerchantActive[0].MerchantData.MerchantCode;
  public totaltable: ITotaltable = {
    countRow : 0,
    currency: '',
    totalCOP: 0,
    totalPEN: 0,
    TotalUSD: 0
  };
  from_date: string = moment().format('YYYY-MM-DD');
  to_date: string = moment().format('YYYY-MM-DD');

  constructor(private api: ApiService, private cookie: CookieService, private router: Router, public translate: TranslateService) {
  }

  ngOnInit() {
    this.translateChange();
    this.changeMerchant();
    console.log(this.MerchantActive);
    this.api.validateMerchantPermissions();
  }

  translateChange() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang == 'en') {
        this.lang = 'en';
      } else if (event.lang == 'es') {
        this.lang = 'es';
      }
    });
  }

  changeMerchant() {
    this.api.merchant$.subscribe(m => {
      if (m.event == 'change-merchant') {
        this.allCashinOrdersData = this.allCashinOrders(m.data, { status: 15 });//SOLO STATUS 15
        this.allCashinOrdersDataTmp = this.allCashinOrders(m.data, { status: 15 });//SOLO STATUS 15
        this.merchant = m.data;
        this.api.validateMerchantPermissions();
      }
    });
  }

  async allCashinOrders(merchant, dataInfo: any = false) {

    let data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: merchant,
      service: 'cashout/transactions/',
      data:{
        from_date:  moment().format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD'),
      }
    }

    let headers = [
      {
        'name': 'ID',
        'key': 'PublicID',
        'opc': true
      },

      {
        'name': 'ID Comercio',
        'key': 'MerchantReference',
        'opc': true
      },
      {
        'name': 'Monto',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Cliente',
        'key': 'Customer-Info-FullName',
        'opc': true
      },

      {
        'name': 'Creación',
        'key': 'TxCreation',
        'opc': true
      },
      {
        'name': 'Banco',
        'key': 'Customer-InfoBank-CustomerBank',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'LastStatus',
        'opc': true
      },
      // {
      //   'name': 'Opciones',
      //   'key': 'opc',
      //   'opc': false,
      //   'functions': ['Ver detalle']
      // },
    ]

    if (dataInfo !== false) {
      data.service = 'cashout/transactions/WithParams';
      data.data = dataInfo;
    }
    
    const result = await this.api.api(data).toPromise() as any[];

    this.totaltable = {
      countRow : 0,
      currency: '',
      totalCOP: 0,
      totalPEN: 0,
      TotalUSD: 0
    };
    result.forEach((item, i) => {
      let laguage = 'es-CO';
      if (item.CurrencyCode === 'PEN') {
        laguage = 'es-PE';
        this.totaltable.totalPEN += Number(String(item.Amount).replace(',', '.'));
      }
      if (item.CurrencyCode === 'USD') {
        laguage = 'en-US';
        this.totaltable.TotalUSD += Number(String(item.Amount).replace(',', '.'));
      }
      if (item.CurrencyCode === 'COP') {
        this.totaltable.totalCOP += Number(String(item.Amount).replace(',', '.'));
      }
      const formatter = new Intl.NumberFormat(laguage, {
        style: 'decimal',
        currency: item.CurrencyCode,
        minimumFractionDigits: 2
      });
      result[i].Amount = formatter.format(Number(String(item.Amount).replace(',', '.')));
      //result[i].Amount = formatter.format(item.Amount);
    });
    
    this.totaltable.currency = 'COP';
    this.totaltable.countRow = result.length;

    return {
      headers: headers,
      data: result,
      source: 'cashout-operations'
    }

  }



  async getFilters() {

    return [
      {
        'name': 'Periodo',
        'key': ['from_date', 'to_date'],
        'type': 'period'
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': this.api.getStatusCashOut()
      }

    ];

  }

  changeDataTable(data) {
    
    this.from_date = data.from_date;
    this.to_date = data.to_date;

    data.status = 15; //SOLO STATUS 15
    
    if (data.type && data.type === 'search') {
      this.allCashinOrdersData = [];
      this.search(this.allCashinOrdersDataTmp, data.data);
    } else {
      this.allCashinOrdersData = this.allCashinOrders(this.cookie.get('merchant'), data);
      this.allCashinOrdersDataTmp = this.allCashinOrdersData;
    }
    
  



  }

  search(array, word) {

    word = word.toLowerCase();

    var res = [];
    var headers = [];

    if (array.headers) {

      headers = array.headers;
      var index = 0;
      var itemsProcessed = 0;
      array.data.forEach(element => {

        for (var k in element) {
          if (element.hasOwnProperty(k)) {
            if (element[k].toString().toLowerCase().indexOf(word) > -1) {
              res.push(array.data[index]);
            } else {
              if (element[k] && typeof (element[k]) == 'object') {
                for (var x in element[k]) {
                  for (var i in element[k][x]) {
                    if (element[k][x][i] && element[k][x][i].toString().toLowerCase().indexOf(word) > -1) {
                      res.push(array.data[index]);
                    }
                  }
                }
              }
            }
          }
        }

        itemsProcessed++
        if (itemsProcessed == array.data.length) {
          this.allCashinOrdersData = {
            data: res,
            headers: headers,
            source: 'cashin-payments-orders'
          };
        }

        index++;
      });

    } else {

      array.then((e: any) => {

        headers = e.headers;
        var index = 0;
        var itemsProcessed = 0;
        e.data.forEach(element => {
          for (var k in element) {
            if (element.hasOwnProperty(k)) {


              if (element[k] && element[k].toString().toLowerCase().indexOf(word) > -1) {
                res.push(e.data[index]);
              } else {
                if (element[k] && typeof (element[k]) == 'object') {
                  for (var x in element[k]) {
                    for (var i in element[k][x]) {
                      if (element[k][x][i] && element[k][x][i].toString().toLowerCase().indexOf(word) > -1) {
                        res.push(e.data[index]);
                      }
                    }
                  }
                }
              }
            }
          }

          itemsProcessed++
          if (itemsProcessed == e.data.length) {
            this.allCashinOrdersData = {
              data: res,
              headers: headers,
              source: 'cashin-payments-orders'
            };
          }

          index++;
        });
      });
    }

  }

  export() {

    let data = {
      token: JSON.parse(this.cookie.get('ud')).token,
      merchant: this.merchant,
      service: 'cashout/transactions/download/',
      data: {
        from_date: this.from_date,
        to_date: this.to_date,
        status: 15, //SOLO STATUS 15
      }
    }

    this.api.api(data);
    $('#charging').addClass('hide');
  }

}
